import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, makeStyles } from '@material-ui/core'

import { TextInput, PasswordInput, ThemeButton } from '../../../ui'
import { PwdRecoveryMessageComponent } from '../../pwd-recovery'
import { useGlobalActions } from '../../global'
import { loginFormStyles } from './login-form.styles'
import { useLoginCalls } from '../hooks/login-calls.hook'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export const LoginFormComponent = () => {
  const classes = makeStyles(loginFormStyles)()
  const { formatMessage } = useIntl()
  const { loginUser } = useGlobalActions()
  const { resendValidation } = useLoginCalls()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [validatedMailForRecovery, setValidatedMailForRecovery] = useState(false)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    setValidatedMailForRecovery(formState.username)
    loginUser(formState)
      .then(() => {
        setLoading(false)
      })
      .catch((code) => {
        if (code === 'inactive_user') {
          setErrorMssg('inactive_user')
        } else {
          setErrorMssg('Usuario o contraseña incorrecta')
        }
        setLoading(false)
      })
  }

  const handleResendValidation = () => {
    resendValidation(validatedMailForRecovery).then(() => {
      setErrorMssg(formatMessage({ id: 'calls.login.error.inactiveUser.sent' }))
    })
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <TextInput
        type="email"
        required
        value={formState['username']}
        name="username"
        title={formatMessage({ id: 'pages.login.form.field.user.title' })}
        placeholder={formatMessage({ id: 'pages.login.form.field.user.placeholder' })}
        onChange={handleChangeForm}
      />
      <PasswordInput
        required
        value={formState['password']}
        name="password"
        title={formatMessage({ id: 'pages.login.form.field.password.title' })}
        placeholder={formatMessage({ id: 'pages.login.form.field.password.placeholder' })}
        onChange={handleChangeForm}
      />
      <PwdRecoveryMessageComponent />
      {errorMssg && (
        <Box mt={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg === 'inactive_user' ? (
              <span>
                {formatMessage({ id: 'calls.login.error.inactiveUser.description.before' })}
                <Link onClick={handleResendValidation} className={classes.link}>
                  {formatMessage({ id: 'calls.login.error.inactiveUser.description.link' })}
                </Link>
                {formatMessage({ id: 'calls.login.error.inactiveUser.description.after' })}
              </span>
            ) : (
              errorMssg
            )}
          </Typography>
        </Box>
      )}
      <ThemeButton type="submit" fullWidth loading={loading}>
        {formatMessage({ id: 'pages.login.button.label' })}
      </ThemeButton>
    </form>
  )
}

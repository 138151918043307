import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { PublicLayoutComponent } from '../../ui'
import {
  Login,
  Signup,
  SignupConfirmation,
  BudgetRequest,
  PasswordRecovery,
  BudgetConfirmation,
} from '../../pages'

export const PublicRouter = () => (
  <PublicLayoutComponent>
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup/confirmation/:signupToken" component={SignupConfirmation} />
      <Route path="/signup" component={Signup} />
      <Route path="/budget-request/:appId" component={BudgetRequest} />
      <Route path="/budget-confirmation/:token" component={BudgetConfirmation} />
      <Route path="/password-recovery/:id" component={PasswordRecovery} />
      <Redirect from="*" to="/login" />
    </Switch>
  </PublicLayoutComponent>
)

import { notificationsActions } from '../constants'

export const notificationsReducer = (state, { type, payload }) => {
  switch (type) {
    case notificationsActions.SET_NOTIFICATIONS_DATA:
      return {
        ...state,
        data: payload,
      }
    case notificationsActions.TOGGLE_NOTIFICATIONS_SHOW:
      return {
        ...state,
        show: !state.show,
      }
    case notificationsActions.SET_NOTIFICATION_SEEN:
      return {
        ...state,
        data: state.data.map((el) => {
          return el.id === payload ? { ...el, visto: true } : el
        }),
      }
    case notificationsActions.SET_NOTIFICATION_UNSEEN:
      return {
        ...state,
        data: state.data.map((el) => {
          return el.id === payload ? { ...el, visto: false } : el
        }),
      }
    default:
      throw new Error('Unknown operation')
  }
}

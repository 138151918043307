import React from 'react'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { ThemeButton } from '../../../../ui/theme-button'

export const BudgetRequestCancelDialog = ({ close, handleCancel }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.budget.view.modalCancel.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>{formatMessage({ id: 'pages.budget.view.modalCancel.text' })}</DialogContent>
      <DialogActions>
        <ThemeButton labelM="large" color="secondary" onClick={handleCancel}>
          {formatMessage({ id: 'pages.budget.view.modalCancel.yes' })}
        </ThemeButton>
        <ThemeButton labelM="large" color="default" onClick={close}>
          {formatMessage({ id: 'pages.budget.view.modalCancel.no' })}
        </ThemeButton>
      </DialogActions>
    </Box>
  )
}

import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useGasAppCalls } from './gas-application-calls.hook'
import { MIN_MILISECONDS_REFRESH_GAS_APPS } from '../constants'
import { setApplicationsData, resetApplications } from '../slices'
import { setApplication, setFailure } from '../slices/gas-applications.slice'

export const useGasAppData = () => {
  const dispatch = useDispatch()
  const {
    getGasApps,
    getGasApp,
    cancelPut,
    uploadDocument,
    reviewCall,
    rollbackPut,
    cancelInstaller,
  } = useGasAppCalls()
  const loaded = useSelector((state) => state.gasApplications.msLoaded)
  const applications = useSelector((state) => state.gasApplications.applications)
  const userId = useSelector((state) => state.global.authData.user_id)
  const cancelPendingApplications = useSelector(
    (state) => state.gasApplications.pendingApplications
  )

  const updateGasApps = useCallback(() => {
    //Don't fetch gas application data before getting user
    if (!userId) {
      return
    }
    //Don't fetch gas application data if we have it fresh
    if (loaded && Date.now() - loaded < MIN_MILISECONDS_REFRESH_GAS_APPS) {
      return
    }
    //Fetch gas application data
    getGasApps(userId)
      .then((data) => {
        dispatch(setApplicationsData(data))
      })
      .catch(() => dispatch(setFailure()))
  }, [dispatch, setApplicationsData, getGasApps, userId, loaded]) // eslint-disable-line

  const resetGasApps = useCallback(() => {
    dispatch(resetApplications())
  }, [dispatch, resetApplications]) // eslint-disable-line

  useEffect(() => {
    //Don't auto-fetch by default if already loaded (it is still possible to refresh it with updateGasApps)
    if (!loaded) {
      updateGasApps()
    }
  }, [loaded]) // eslint-disable-line

  const cancelApplication = useCallback(
    (application, payload) =>
      new Promise(async (resolve, reject) => {
        try {
          await cancelPut(application['id'], payload)
          const dataGas = await getGasApp(application.id)
          dispatch(setApplication(dataGas))
          resolve(dataGas)
        } catch (response) {
          return reject(response)
        }
      }),
    [] // eslint-disable-line
  )

  const cancelGuild = useCallback(
    (application, payload) =>
      new Promise(async (resolve, reject) => {
        try {
          await cancelInstaller(application.id, payload)
          const dataGas = await getGasApp(application.id)
          dispatch(setApplication(dataGas))
          resolve(dataGas)
        } catch (response) {
          return reject(response)
        }
      }),
    []
  )

  const rollbackApp = useCallback((application, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        await rollbackPut(application['id'], payload)
        const dataGas = await getGasApp(application.id)
        dispatch(setApplication(dataGas))
        resolve(dataGas)
      } catch (response) {
        return reject(response)
      }
    })
  }, []) // eslint-disable-line

  const requestReview = useCallback(
    (id, payload, file) =>
      new Promise(async (resolve, reject) => {
        try {
          if (file) {
            const document = {
              documento: file.data.split(',')[1],
              nombre: file.name.replace(/\.[^/.]+$/, ''),
              tipo: 'FC',
              extension: file.type,
              cliente_id: parseInt(userId),
              solicitud_id: id.toString(),
              save_event: false,
            }
            const { data } = await uploadDocument(document)
            payload['list_doc_id'] = data['id'].toString()
          }
          await reviewCall(id, payload)
          const dataGas = await getGasApp(id)
          dispatch(setApplication(dataGas))
          resolve(dataGas)
        } catch (response) {
          return reject(response)
        }
      }),
    [] // eslint-disable-line
  )

  return {
    cancelPendingApplications,
    loaded,
    applications,
    updateGasApps,
    resetGasApps,
    cancelApplication,
    rollbackApp,
    requestReview,
    cancelGuild,
  }
}

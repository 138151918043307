import React from 'react'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { EditProfileFormComponent } from './edit-profile-form.component'

export const EditProfileDialogComponent = ({ close, data }) => (
  <Box p={4}>
    <DialogTitle>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="h4" color="primary">
          Información personal
        </Typography>
      </Box>
    </DialogTitle>
    <DialogContent>
      <EditProfileFormComponent {...{ data, close }} />
    </DialogContent>
    {/* <DialogActions>
      <Button onClick={close}>Cerrar</Button>
    </DialogActions> */}
  </Box>
)

export const budgetRequestViewStyles = (theme) => ({
  root: { padding: 20 },
  section1: {
    padding: '0 0 15px 0',
  },
  section1_1: {
    padding: '12px 0',
  },
  section2: {
    padding: '20px 0',
  },
  section2_1: {
    marginRight: 20,
  },
  panel: {
    backgroundColor: '#f3f3f3',
    borderRadius: 6,
    marginBottom: 20,
    padding: 5,
  },
  buttonsWrapper: {
    marginTop: 24,
    display: 'flex',
    flexWrap: 'wrap-reverse',
    justifyContent: 'space-around',
  },
  textPadding: {
    padding: '0 4px',
  },
  linkButton: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
})

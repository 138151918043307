import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Typography, CircularProgress, Container } from '@material-ui/core'

import { ThemeButton } from '../../../ui'
import { BudgetConfirmationView } from '../../../features/budget-confirmation'
import { useBudgetConfirmationContext } from '../../../features/budget-confirmation'
import { REJECTED_STATE } from '../../../features/budget-confirmation'

export const BudgetConfirmationPage = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { application, loading, error, cancelled } = useBudgetConfirmationContext()
  let content = null

  const handleNavigateToLogin = () => history.push('/login')

  if (loading) {
    content = (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress size={40} />
      </Box>
    )
  } else if (error) {
    content = (
      <Typography>
        {formatMessage({ id: 'pages.budgetConfirmation.errorMessage.title' })}
      </Typography>
    )
  } else if (!!application.presupuesto_exterior.d_presupuesto_aceptado) {
    content = (
      <>
        <Typography paragraph>
          {formatMessage({ id: 'pages.budgetConfirmation.notAvailableMessage.title' })}
        </Typography>
        <Box display="flex" justifyContent="center">
          <ThemeButton labelM="medium" color="primary" onClick={handleNavigateToLogin}>
            {formatMessage({ id: 'pages.budgetConfirmation.redirectButton.title' })}
          </ThemeButton>
        </Box>
      </>
    )
  } else if (application.estado === REJECTED_STATE) {
    content = (
      <Typography>
        {formatMessage({ id: 'pages.budgetConfirmation.rejectedMessage.title' })}
      </Typography>
    )
  } else if (cancelled) {
    content = (
      <Typography variant="h6">
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          {formatMessage({ id: 'pages.budgetConfirmation.rejectionConfirmedMessage.title' })}
        </Box>
      </Typography>
    )
  } else {
    content = application ? <BudgetConfirmationView /> : null
  }

  return (
    <Container maxWidth="md">
      <Box pt={[1, 4]} pb={[1, 4]} height="100%">
        {content}
      </Box>
    </Container>
  )
}

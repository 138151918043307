import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Divider, Hidden, Checkbox } from '@material-ui/core'

import { ThemeButton, ExpansionPanel, GridRow } from '../../../../../ui'
import { ReactComponent as ConnectionIcon } from '../../../../../resources/images/icon_connection.svg'
import { BudgetMandatoryMssgComponent } from './budget-mandatory-mssg.component'
import {
  BUDGET_GRID_ROW_ITEMS,
  BUDGET_STEP_UNCANCELLABLE_STATES,
  MUST_ANSWER_CANCEL_REQUEST,
  CAN_SELECT_INSTALLER,
} from './constants'
import { APPLICATION_STATES } from '../../../constants'
import { feedback } from '../../../../../core/feedback'
import { useBudgetStep } from './budget-step.hook'
import { CancelBtn, RequestChangesBtn, GetInstallerTxt } from '../../buttons'
import { budgetStepStyles } from './budget-step.styles'

export const BudgetStepComponent = withStyles(budgetStepStyles)(({ classes, model }) => {
  const { formatMessage, formatNumber } = useIntl()
  const { downloadBudgetFile, goToNextStep } = useBudgetStep()
  const [loading, setLoading] = useState(false)

  const canChangeInstaller =
    model['camino_solicitud'] === '01' &&
    (CAN_SELECT_INSTALLER[model.estado] ||
      (model?.estado === '37' && model.certificado_IRG3?.id === null)) &&
    (model['d_IRG3'] === null || model['certificado_IRG3_id'] === null) &&
    !model['gran_consumo_id']

  const isCancellationPermitted = !BUDGET_STEP_UNCANCELLABLE_STATES[model['estado']]
  const mustRequestCancel = MUST_ANSWER_CANCEL_REQUEST[model['estado']]
  const pendingAccept = model['estado'] === APPLICATION_STATES.BUDGET_SENDED.KEY
  const freeL = model['presupuesto_exterior']['distancia_asumida_nedgia'] || '-'
  const priceL = model['presupuesto_exterior']['precio_metro_tuberia']
    ? formatNumber(model['presupuesto_exterior']['precio_metro_tuberia'], {
        style: 'currency',
        currency: 'EUR',
      })
    : '-'
  const idDisclaimer =
    'pages.gasApplications.wizard.budget.detail.description.' +
    (parseFloat(model['presupuesto_exterior']['precio_instalacion_tuberia_iva']) === 0
      ? 'included'
      : 'excluded')

  const getState = (state) => {
    if (state === APPLICATION_STATES.REVIEW.KEY) return 'pending_modification'
    if (state === APPLICATION_STATES.BUDGET_SENDED.KEY) return 'pending_acceptance'
    if (state > APPLICATION_STATES.BUDGET_SENDED.KEY) return 'accepted'
    return 'rejected'
  }
  const getBudgetState = () => {
    if (
      model['estado'] === APPLICATION_STATES.REJECTED.KEY ||
      model['estado'] === APPLICATION_STATES.REJECTED_2.KEY
    ) {
      return getState(model['estado_anterior'])
    } else {
      return getState(model['estado'])
    }
  }

  const [checked, setChecked] = useState(false)

  const handleNext = (e) => {
    e.preventDefault()
    setLoading(true)
    goToNextStep(model['presupuesto_exterior']['id'])
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const handleDownloadFile = () => {
    const fileId =
      APPLICATION_STATES.BUDGET_SENDED.KEY === model.estado
        ? model['presupuesto_exterior'].d_presupuesto?.id
        : model['presupuesto_exterior']['d_presupuesto_aceptado']['id']
    downloadBudgetFile(fileId)
  }

  const handleCheck = () => {
    feedback('warning', formatMessage({ id: 'pages.gasApplications.wizard.budget.checkboxAlert' }))
  }

  return (
    <ExpansionPanel
      isExpanded={pendingAccept}
      summary={
        <>
          <Box display="flex" mb={1}>
            <Box display="flex" alignItems="center" flexGrow={2}>
              <Hidden xsDown className={classes.section_1_1_1}>
                <ConnectionIcon />
              </Hidden>
              <Typography variant="h5">
                {formatMessage({ id: 'pages.budget.view.panel1.summary.left.subtitle' })}
              </Typography>
            </Box>
            <Box flexGrow={1} textAlign="right">
              <Typography variant="body2" color="textSecondary">
                {formatMessage({ id: 'pages.budget.view.panel1.summary.right.title' })}
              </Typography>
              <Typography variant="h5" color="secondary">
                {formatNumber(
                  model['presupuesto_exterior']['precio_instalacion_tuberia'] +
                    model['presupuesto_exterior']['precio_instalacion_tuberia_iva'],
                  {
                    style: 'currency',
                    currency: 'EUR',
                  }
                )}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Typography variant="body2" className={classes.marginRight}>
                  {formatMessage({ id: 'pages.gasApplications.wizard.budget.state.label' })}:
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatMessage({
                    id: `pages.gasApplications.wizard.budget.state.${getBudgetState()}`,
                  })}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Typography variant="body2">
            {formatMessage({ id: 'pages.gasApplications.wizard.steps.0.tooltip' })}
          </Typography>
        </>
      }
      details={
        <Box>
          <Typography variant="subtitle1">
            {formatMessage({ id: 'pages.budget.view.panel1.detail.title1' })}
          </Typography>
          <Box mt={1} mb={1}>
            <GridRow items={BUDGET_GRID_ROW_ITEMS} data={model} justify="space-between" />
          </Box>
          <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
            {formatMessage({ id: idDisclaimer }, { freeL: freeL, priceL: priceL })}
          </Typography>
        </Box>
      }
      footer={
        <form onSubmit={handleNext}>
          {pendingAccept && (
            <>
              <Divider light />
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  size="small"
                  checked={checked}
                  onClick={handleCheck}
                  required
                  // disabled={checked}
                />
                <BudgetMandatoryMssgComponent
                  classes={classes}
                  setChecked={setChecked}
                  checked
                  fileId={model['presupuesto_exterior'].d_presupuesto?.id}
                />
              </Box>
            </>
          )}
          <Divider light style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box flex={1}>
              <Typography variant="subtitle2">
                {formatMessage({ id: 'pages.gasApplications.wizard.budget.downloadMssg' })}
              </Typography>
              <span onClick={handleDownloadFile}>
                <Typography variant="body2" className={classes.downloadLink}>
                  {formatMessage({
                    id: 'pages.gasApplications.wizard.budget.downloadLink.label',
                  })}
                </Typography>
              </span>
            </Box>
            <Box display="flex" alignItems="center">
              {pendingAccept && (
                <Box display="flex">
                  <ThemeButton
                    labelM="large"
                    color="primary"
                    type="submit"
                    disabled={!checked}
                    loading={loading}
                  >
                    {formatMessage({ id: 'global.accept' })}
                  </ThemeButton>
                  <Box ml={1}>
                    <RequestChangesBtn {...{ model }} />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {canChangeInstaller || (isCancellationPermitted && !mustRequestCancel) ? (
            <Divider light style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
          ) : null}
          <Box display="flex" justifyContent={canChangeInstaller ? 'space-between' : 'flex-end'}>
            {canChangeInstaller ? (
              <Box display="flex" height="100%" alignItems="center" flexGrow={1} mt={2}>
                <GetInstallerTxt {...{ model }} />
              </Box>
            ) : null}
            {isCancellationPermitted && !mustRequestCancel ? (
              <Box ml={1}>
                <CancelBtn {...{ model }} />
              </Box>
            ) : null}
          </Box>
        </form>
      }
    />
  )
})

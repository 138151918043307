import { configureStore } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { globalReducer } from '../../features/global/slices'
import { budgetRequestReducer } from '../../features/budget-request/slices'
import { gasApplicationsReducer } from '../../features/gas-applications/slices'
import { profileReducer } from '../../features/profile/slices'
import { combosReducer } from '../../features/combos/slices'

// TODO: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

export default configureStore({
  reducer: {
    global: globalReducer,
    router: connectRouter(createBrowserHistory()),
    budgetRequest: budgetRequestReducer,
    gasApplications: gasApplicationsReducer,
    profile: profileReducer,
    combos: combosReducer,
  },
})

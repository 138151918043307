import axios from 'axios'

import store from '../../redux/store'
import { setAuthData } from '../../../features/global'
import { loadLocalStorage, saveLocalStorage } from '../../utils'

import { PUBLIC_INSTANCE_CONFIG, PRIVATE_INSTANCE_CONFIG } from '../constants'

const publicInstance = axios.create(PUBLIC_INSTANCE_CONFIG)

const privateInstance = axios.create(PRIVATE_INSTANCE_CONFIG)
privateInstance.interceptors.request.use((request) => {
  const userState = loadLocalStorage('apc-auth')

  if (userState?.token) {
    request.headers.Authorization = 'Bearer ' + userState.token
  }
  return request
})

const URL_REFRESH_TOKEN = '/login/refresh_token'

let refreshTokenPool = { requestCount: 0 }
privateInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { status, config } = error.response
    const userState = loadLocalStorage('apc-auth')
    if (config.url === URL_REFRESH_TOKEN || status !== 401 || error.config.retry) {
      throw error
    }
    try {
      if (!refreshTokenPool.promise) {
        const payload = { refresh_token: userState.refresh_token }
        refreshTokenPool.promise = privateInstance
          .post(URL_REFRESH_TOKEN, payload)
          .then((response) => {
            const payload = { ...userState, ...response.data }
            saveLocalStorage('dsc', { 'apc-auth': payload })
            store.dispatch(setAuthData({ ...payload, isAuthorized: true }))
            return response
          })
          .catch(() => {
            throw new Error('token_refresh_failure')
          })
        refreshTokenPool.requestCount = 1
      } else {
        refreshTokenPool.requestCount++
      }
      const response = await refreshTokenPool.promise
      refreshTokenPool.requestCount--
      if (refreshTokenPool.requestCount === 0) {
        refreshTokenPool.promise = null
      }
      const newRequestConfig = {
        ...config,
        retry: true,
        headers: { ...config.headers, Authorization: 'Bearer ' + response.data.token },
      }
      return publicInstance(newRequestConfig)
    } catch {
      console.log('Error fetching new access token')
      saveLocalStorage('dsc', {})
      setTimeout(() => window.location.reload(), 1000)
      throw new Error('token_refresh_failure')
    }
  }
)

export const instances = { publicInstance, privateInstance }

export const BUDGET_GRID_ROW_ITEMS = [
  {
    label: 'pages.budget.view.panel1.detail.grid.item2',
    valueParsed: (model, intl) =>
      intl.formatNumber(model['presupuesto_exterior']['distancia_real'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
  {
    label: 'pages.budget.view.panel1.detail.grid.item3',
    valueParsed: (model, intl) =>
      intl.formatNumber(model['presupuesto_exterior']['ampliacion_asumida_por_cliente'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
]

export const URL_CANCEL_LINK = `${process.env.REACT_APP_WP_URL}/no-me-interesa`

const OPEN_TIME_SECONDS = 0.2

export const drawerStyles = (theme) => ({
  '@keyframes reveal': {
    '0%': { opacity: 0 },
    '30%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  box: {
    height: '100%',
    display: 'inline-block',
    backgroundColor: 'white',
    transition: `all ${OPEN_TIME_SECONDS}s ease-out`,
    overflow: 'hidden',
    minWidth: '0px',
  },
  boxFloat: { position: 'fixed', right: '0' },
  header: { display: 'flex', justifyContent: 'space-between', alignitems: 'center' },
  main: {
    padding: '1em',
    height: 'calc(100%)',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '1em',
    },
  },
  opened: {
    width: '25%',
    maxWidth: '100%',
    minWidth: '300px',
    '& *': { animation: `${3 * OPEN_TIME_SECONDS}s $reveal` },
  },
  closed: {
    width: '0px',
  },
  button: { width: '50px' },
  fullyOpened: { width: '100%' },
})

import React, { createContext, useReducer, useContext } from 'react'

import { NotificationDialog } from '../components'

import { NOTIFICATIONS_DEFAULT, notificationsActions } from '../constants'
import { notificationsReducer } from '../reducers'
import * as auxActions from './auxActions' //Provisional auxiliary actions
import { useNotificationCalls } from '../hooks'
import { ModalContext } from '../../../ui/modal/contexts'

//Context to export:
export const NotificationsContext = createContext()

//Provider and state management through a reducer
export const NotificationsContextProvider = ({ children }) => {
  //Link to reducer
  const [state, dispatch] = useReducer(notificationsReducer, NOTIFICATIONS_DEFAULT)
  const { getNotifications, seenNotifications } = useNotificationCalls()

  //Auxiliary modal trigger
  const {
    actions: { open },
  } = useContext(ModalContext)

  //Allowed actions:
  const toggleNotificationsShow = () => {
    dispatch({
      type: notificationsActions.TOGGLE_NOTIFICATIONS_SHOW,
    })
  }
  const loadNotifications = async (userId) => {
    const { data } = await getNotifications(userId)
    dispatch({
      type: notificationsActions.SET_NOTIFICATIONS_DATA,
      payload: data,
    })
  }
  const showNotification = async (id) => {
    const notification = state.data.filter((el) => el.id === id)[0]
    if (!notification) {
      throw new Error('Invalid notification', id)
    }
    //Modal opening
    open({
      Component: NotificationDialog,
      data: { notification: notification, setNotificationUnseen: setNotificationUnseen },
      type: 'centered',
    })
    setNotificationSeen(id)
  }

  const setNotificationSeen = async (id) => {
    const notification = state.data.filter((el) => el.id === id)[0]
    if (!notification) {
      return
    }
    await seenNotifications(id)
    dispatch({
      type: notificationsActions.SET_NOTIFICATION_SEEN,
      payload: id,
    })
  }

  const setNotificationUnseen = async (id) => {
    await auxActions.markNotificationUnseen(id)
    dispatch({
      type: notificationsActions.SET_NOTIFICATION_UNSEEN,
      payload: id,
    })
  }

  //Return provider:
  return (
    <NotificationsContext.Provider
      value={{
        notificationsData: state.data,
        notificationsShow: state.show,
        toggleNotificationsShow: toggleNotificationsShow,
        loadNotifications: loadNotifications,
        showNotification: showNotification,
        setNotificationSeen: setNotificationSeen,
        setNotificationUnseen: setNotificationUnseen,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export const wizardStyles = (theme) => ({
  wrapper: { width: '100%' },
  stepper: {
    padding: 0,
    margin: '10px -30px 20px -30px',
  },
  view: {
    backgroundColor: '#f3f3f3',
    borderRadius: 6,
    padding: 15,
  },
})

export const stepStyles = (theme) => ({
  navegable: {
    cursor: 'pointer',
  },
  label: {
    fontSize: 11,
    padding: '0 20px',
    clor: theme.palette.primary.main,
  },
})

export const stepIconStyles = (theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    width: 20,
    height: 20,
    border: '3.5px solid #004571',
    borderRadius: '50%',
  },
  disabled: {
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: theme.palette.grey[500],
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    zIndex: 1,
    fontSize: 18,
    padding: 4,
    borderRadius: '50%',
  },
})

export const stepConnectorStyles = (theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.grey[500],
    borderTopWidth: 2,
    borderRadius: 1,
  },
})

export const networkStepStyles = (theme) => ({
  downloadLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  marginRight: {
    marginRight: 4,
  },
  subtitle: {
    fontFamily: 'FSEmeric-Medium',
  },
})

import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

const DEFAULT_COMBOS = {
  survey_options: {
    isLoading: false,
    data: [
      {
        key: 'respuesta_1',
        value: 'Solo estoy buscando información',
      },
      {
        key: 'respuesta_2',
        value: 'No entiendo el proceso',
      },
      {
        key: 'respuesta_3',
        value: 'Razones económicas',
      },
      {
        key: 'respuesta_4',
        value: 'No era lo que estaba buscando',
      },
      {
        key: 'respuesta_5',
        value: 'Otra razón (por favor, díganosla)',
      },
    ],
  },
}

const combosSlice = createSlice({
  name: 'combos',
  // initialState: {},
  initialState: DEFAULT_COMBOS,
  reducers: {
    getCombo: (state, { payload }) => {
      state[payload] = {}
      state[payload].data = []
      state[payload].isLoading = true
    },
    setCombo: (state, { payload }) => {
      state[payload.comboId].data = payload.data
      state[payload.comboId].isLoading = false
    },
    setComboLoading: (state, { payload }) => {
      state[payload.comboId].isLoading = payload.isLoading
    },
  },
})

export const combosReducer = combosSlice.reducer

export const { setLoading, getCombo, setCombo, setComboLoading } = combosSlice.actions

export const setComboById = (comboId, order = false) => (dispatch) => {
  const { get } = requests

  dispatch(getCombo(comboId))
  get('/combo_box', { params: { parent_id: comboId } })
    .then(({ data }) => {
      if (order) {
        data = data.sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
      }
      dispatch(setCombo({ comboId, data }))
    })
    .catch(({ response }) => {
      console.error('Error recuperando combo', response && response.data)
      dispatch(setComboLoading({ comboId, isLoading: false }))
    })
}

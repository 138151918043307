import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Typography, Box } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { selectUser } from '../../slices'
import { useGasAppData } from '../../../../features/gas-applications'
import { ReactComponent as EditIcon } from '../../../../resources/images/icon_edit.svg'
import { ModalContext } from '../../../../ui/modal/contexts'
import { ThemeButton, GridRow } from '../../../../ui'
import { EditProfileDialog } from '../edit'
import { ChangePwdDialog } from '../change-pwd'
import { USER_GRID_ROW_ITEMS } from '../../constants'
import { useGlobalActions } from '../../../global'
import { profileUserStyles } from './profile-user.styles'

export const ProfileUserComponent = withStyles(profileUserStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { resetGasApps } = useGasAppData()
  const { logoutUser } = useGlobalActions()
  const {
    actions: { open },
  } = useContext(ModalContext)
  const user = useSelector(selectUser)

  const handleLogout = () => {
    resetGasApps()
    logoutUser()
  }

  const handleOpenEditProfileModal = () => {
    console.log('user', user)
    open({
      Component: EditProfileDialog,
      data: { data: user },
      type: 'responsive',
      closable: false,
    })
  }


  const handleOpenChangePwdModal = () =>
    open({
      Component: ChangePwdDialog,
      // data: { data: user },
      type: 'responsive',
    })

  useEffect(() => {
    if (!user['nombre']) handleOpenEditProfileModal()
  }, []) // eslint-disable-line

  return (
    <Box mb={3}>
      <Box display="flex" mb={1} flexWrap="wrap">
        <Box display="flex" alignItems="center" flexGrow={4} mr={2}>
          <AccountCircleOutlinedIcon className={classes.userImg} />
          <Typography variant="h4" color="primary">
            {`${user['nombre'] || ''} ${user['apellidos'] || ''}`}
          </Typography>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <ThemeButton
              color="primary"
              icon={<EditIcon className={classes.primaryButtonIcon} />}
              onClick={handleOpenEditProfileModal}
            >
              {formatMessage({ id: 'pages.profile.header.button1' })}
            </ThemeButton>
          </Box>
          <Box mr={2}>
            <ThemeButton
              color="primary"
              icon={<EditIcon className={classes.primaryButtonIcon} />}
              onClick={handleOpenChangePwdModal}
            >
              {/* {formatMessage({ id: 'pages.profile.header.button1' })} */}
              Cambiar contraseña
            </ThemeButton>
          </Box>
          <ThemeButton color="primary" onClick={handleLogout}>
            {formatMessage({ id: 'pages.profile.header.button2' })}
          </ThemeButton>
        </Box>
      </Box>
      <Box bgcolor="common.white" borderRadius={6} p={3}>
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage({ id: 'pages.profile.user.title' })}:
        </Typography>
        <GridRow items={USER_GRID_ROW_ITEMS} data={user} justify="space-between" />
      </Box>
    </Box>
  )
})

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { requests } from '../../../../../core/ajax'
import { feedback } from '../../../../../core/feedback'
import { downloadFile } from '../../../../../core/utils'
import { setApplication } from '../../../slices'

export const useBulletinStep = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { getById, putById } = requests

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const downloadBulletinFile = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/documento', id)
          .then(({ data }) => downloadFile(data['documento'], data['extension'], data['nombre']))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const uploadBulletinFile = useCallback(
    (application, fileData, documentIdentification) =>
      new Promise((resolve, reject) => {
        const payload = { [documentIdentification]: fileData.id }
        putById('/solicitudes/update_docs', application['id'], payload)
          .then(({ data }) => dispatch(setApplication(data)))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  return {
    downloadBulletinFile,
    uploadBulletinFile,
  }
}

import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Typography, Box } from '@material-ui/core'

import { selectSupplies } from '../../slices'
// import { ExpansionPanel, GridRow } from '../../../../ui'
// import { SUPPLY_GRID_ROW_ITEMS } from '../../constants'
import { profileSuppliesStyles } from './profile-supplies.styles'

export const ProfileSuppliesComponent = withStyles(profileSuppliesStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  const supplies = useSelector(selectSupplies)

  return (
    <Box bgcolor="common.white" borderRadius={6} p={3}>
      <Typography variant="h6" color="primary" paragraph>
        {formatMessage({ id: 'pages.profile.supplies.title' })}:
      </Typography>
      {supplies.map((item, i) => {
        return (
          <Box key={i} className={classes.panel} borderRadius={6} p={1} mb={2}>
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Box>
                <Typography variant="subtitle2">
                  {formatMessage({ id: 'pages.profile.supplies.fields.address' })}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {`${item['tipo_calle']} ${item['direccion']} ${item['numero']}` +
                    `${item['bloque'] ? '-' + item['bloque'] : ''} ` +
                    `${item['escalera'] ? '-' + item['escalera'] : ''} ` +
                    `${item['piso']} ${item['puerta']}` +
                    '\n' +
                    `${item['cod_postal']} ${item['municipio']}`}
                </Typography>
              </Box>
              <Box>
                <Typography style={{ textAlign: 'right' }} variant="subtitle2">
                  {formatMessage({ id: 'pages.profile.supplies.fields.cups' })}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {item['cups']}
                </Typography>
              </Box>
            </Box>
            {/* <ExpansionPanel
              triggerPosition="right"
              summary={
                <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                  <Box>
                    <Typography variant="subtitle2">
                      {formatMessage({ id: 'pages.profile.supplies.fields.cups' })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item['CUPS']}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">
                      {formatMessage({ id: 'pages.profile.supplies.fields.address' })}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {`${item['tipo_calle'] === 'calle' ? 'C/' : item['tipo_calle']} ${
                        item['direccion']
                      } ${item['numero']}, 
                        ${item['cod_postal']} ${item['municipio']}`}
                    </Typography>
                  </Box>
                </Box>
              }
              details={
                <Box className={classes.subpanel}>
                  <Typography variant="subtitle1">
                    {formatMessage({ id: 'pages.profile.supplies.fields.title' })}
                  </Typography>
                  <GridRow items={SUPPLY_GRID_ROW_ITEMS} data={item} justify="space-between" />
                </Box>
              }
            /> */}
          </Box>
        )
      })}
    </Box>
  )
})

import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, RatingInput, SelectInput } from '../../../../../ui'
import { CHANGE_INSTALLER_OBSERVATIONS } from './change-installer-dialog.constants'

export const ChangeInstallerDialog = ({ model, handleRedirectWP }) => {
  const { formatMessage } = useIntl()
  const [observations, setObservations] = useState('')
  const [hasObservations, setHasObservations] = useState(false)

  const handleChangeObservations = (e) => {
    const {
      target: { value },
    } = e
    setHasObservations(value !== '' ? true : false)
    setObservations(value)
  }

  return (
    <Box p={4} mb={1}>
      <Typography variant="h4" color="primary" align="center" paragraph>
        {formatMessage({ id: 'pages.gasApplications.dialogs.setInstaller.title' })}
      </Typography>
      <Box mb={1}>
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.gasApplications.dialogs.setInstaller.guild.info' })}
        </Typography>
      </Box>
      <Box>
        <Box mt={2} display="flex">
          <Typography variant="subtitle1">
            {formatMessage({ id: 'pages.gasApplications.dialogs.setInstaller.guild.info.name' })}
          </Typography>
          <Box ml={1}>
            <Typography>{model.empresa_ca?.nombre}</Typography>
          </Box>
        </Box>
        <Box mt={2} display="flex">
          <Typography variant="subtitle1">
            {formatMessage({ id: 'pages.gasApplications.dialogs.setInstaller.guild.info.phone' })}
          </Typography>
          <Box ml={1}>
            <Typography>{model.empresa_ca?.telefono}</Typography>
          </Box>
        </Box>
        <Box mt={2} display="flex" mb={1}></Box>
        <Box>
          {model.empresa_ca?.id ? (
            <SelectInput
              fullWidth={true}
              required={true}
              title={formatMessage({
                id: 'pages.gasApplications.dialogs.setInstaller.guild.observaciones.title',
              })}
              placeholder={formatMessage({
                id: 'pages.gasApplications.dialogs.setInstaller.guild.observaciones.placeholder',
              })}
              value={observations}
              values={CHANGE_INSTALLER_OBSERVATIONS}
              name="observations"
              onChange={handleChangeObservations}
            />
          ) : null}
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <ThemeButton
          labelM="large"
          color="primary"
          onClick={() => handleRedirectWP(observations)}
          disabled={!hasObservations}
        >
          {formatMessage({ id: 'global.changeInstaller' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}

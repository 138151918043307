import { APPLICATION_STATES } from './application-states.constants'

export const MIN_MILISECONDS_REFRESH_GAS_APPS = 2000

export const FILTERED_STATES = {
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: APPLICATION_STATES.NOT_ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.ASSIGNED.KEY]: APPLICATION_STATES.ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: APPLICATION_STATES.BUDGET_CREATED.DESCRIPTION,
}

export const PROCESSING_STATES = {
  [APPLICATION_STATES.CREATED.KEY]: APPLICATION_STATES.CREATED.DESCRIPTION,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: APPLICATION_STATES.NOT_ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.ASSIGNED.KEY]: APPLICATION_STATES.ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: APPLICATION_STATES.BUDGET_CREATED.DESCRIPTION,
}

export const CAN_SELECT_INSTALLER = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.VALIDATION_PENDING.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
}

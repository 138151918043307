import React from 'react'
import { useIntl } from 'react-intl'

import {
  BudgetStep,
  PaymentStep,
  BulletinStep,
  NetworkStep,
  ContractStep,
  PutgasStep,
} from '../gas-app-steps'
import { WIZARD_STEPS } from '../../constants'

export const useAppWizardSteps = () => {
  const { formatMessage } = useIntl()

  const budgetWizardStep = {
    id: WIZARD_STEPS.BUDGET.KEY,
    label: formatMessage({ id: WIZARD_STEPS.BUDGET.LABEL_ID }),
    tooltip: formatMessage({ id: WIZARD_STEPS.BUDGET.TOOLTIP_ID }),
    component: (props) => <BudgetStep {...props} />,
  }

  const paymentWizardStep = {
    id: WIZARD_STEPS.PAYMENT.KEY,
    label: formatMessage({ id: WIZARD_STEPS.PAYMENT.LABEL_ID }),
    tooltip: formatMessage({ id: WIZARD_STEPS.PAYMENT.TOOLTIP_ID }),
    component: (props) => <PaymentStep {...props} />,
  }

  const bulletinWizardStep = {
    id: WIZARD_STEPS.BULLETIN.KEY,
    label: formatMessage({ id: WIZARD_STEPS.BULLETIN.LABEL_ID }),
    tooltip: formatMessage({ id: WIZARD_STEPS.BULLETIN.TOOLTIP_ID }),
    component: (props) => <BulletinStep {...props} />,
  }

  const bulletinWizardExteriorStep = {
    id: WIZARD_STEPS.BULLETIN.KEY,
    label: formatMessage({ id: WIZARD_STEPS.BULLETIN_EXTERIOR.LABEL_ID }),
    tooltip: formatMessage({ id: WIZARD_STEPS.BULLETIN_EXTERIOR.TOOLTIP_ID }),
    component: (props) => <BulletinStep {...props} />,
  }

  const networkWizardStep = {
    id: WIZARD_STEPS.NETWORK.KEY,
    label: formatMessage({ id: WIZARD_STEPS.NETWORK.LABEL_ID }),
    tooltip: formatMessage({ id: WIZARD_STEPS.NETWORK.TOOLTIP_ID }),
    component: (props) => <NetworkStep {...props} />,
  }

  const contractWizardStep = {
    id: WIZARD_STEPS.CONTRACT.KEY,
    label: formatMessage({ id: WIZARD_STEPS.CONTRACT.LABEL_ID }),
    tooltip: formatMessage({ id: WIZARD_STEPS.CONTRACT.TOOLTIP_ID }),
    component: (props) => <ContractStep {...props} />,
  }

  const putgaWizardStep = {
    id: WIZARD_STEPS.PUTGAS.KEY,
    label: formatMessage({ id: WIZARD_STEPS.PUTGAS.LABEL_ID }),
    tooltip: formatMessage({ id: WIZARD_STEPS.PUTGAS.TOOLTIP_ID }),
    component: (props) => <PutgasStep {...props} />,
  }
  return {
    budgetWizardStep,
    paymentWizardStep,
    bulletinWizardStep,
    bulletinWizardExteriorStep,
    networkWizardStep,
    contractWizardStep,
    putgaWizardStep,
  }
}

import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, SelectInput } from '../../../../../ui'
import { useCombos } from '../../../../combos/hooks'

export const CancelAppDialog = ({ close, application, cancelApplication }) => {
  const { formatMessage } = useIntl()
  const combos = useCombos(['motivos_anulacion_cliente'])

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    let reasons = {}
    combos.survey_options.data.forEach((reason) => (reasons[reason.key] = reason.value))
    const payload = {
      motivo_anulacion_cliente: formState.respuesta || '',
    }
    setLoading(true)
    cancelApplication(application, payload)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <Box p={4}>
      <Typography variant="h4" color="primary" align="center" paragraph>
        {formatMessage({ id: 'pages.gasApplications.dialogs.cancelApp.title' })}
      </Typography>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <SelectInput
            required
            fullWidth
            name="respuesta"
            title={`${formatMessage({
              id: 'pages.gasApplications.dialogs.cancelApp.fields.1.title',
            })}:`}
            value={formState['respuesta']}
            values={combos?.motivos_anulacion_cliente?.data || []}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="large" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="large"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}

export const BudgetRequestAuthStyles = (theme) => ({
  root: {
    padding: '60px 100px',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  description: {
    textAlign: 'center',
  },
  form: {
    padding: 0,
  },
  field: {
    textAlign: 'center',
    padding: '0 40px',
  },
  button: {
    textAlign: 'center',
  },
})

import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, DialogContent, DialogActions } from '@material-ui/core'

import { Communications } from './communications.component'
import {
  COMMS_CHANNELS_CLIENTE,
} from './communications.constants'
import { ThemeButton } from '../../../../ui'

export const CommunicationsDialog = ({ close, application, checkUnreadComms }) => {
  const { formatMessage } = useIntl()
  const role = "client"

  const handleClose = () => {
    checkUnreadComms()
    close()
  }

  return (
    <>
      <DialogContent>
        <Communications {...{ application, profile: role, channels: COMMS_CHANNELS_CLIENTE(application.estado) }} />
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <ThemeButton color="primary" labelM="lg" onClick={handleClose}>
            {formatMessage({ id: 'global.close' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </>
  )
}

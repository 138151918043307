export const contactStyles = (theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '60px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
    },
  },
  corporativeLink: {
    color: theme.palette.primary.main,
  },
  sectionTitle: {},
  sectionText: {
    marginLeft: '1em',
  },
})

import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'

import { PrivateRouter } from './private.router'
import { PublicRouter } from './public.router'
import { NotificationsContextProvider } from '../../features/notifications/contexts'
import { setAuthData, setGlobalLoading } from '../../features/global'
import { loadLocalStorage } from '../../core/utils'
import {NoticesPopupComponent} from "../../features/notices";

export const BootstrapRouter = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state) => state.global)
  const { isAuthorized } = useSelector((state) => state.global.authData)
  const localAuthData = loadLocalStorage('apc-auth')

  useEffect(() => {
    if (!isAuthorized && localAuthData?.token) {
      const payload = { ...localAuthData, isAuthorized: true }
      dispatch(setAuthData(payload))
    } else {
      dispatch(setGlobalLoading(false))
    }
  }, []) // eslint-disable-line

  return useMemo(
    () =>
      isLoading ? (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      ) : !isAuthorized ? (
        <PublicRouter />
      ) : (
        <>
        <NotificationsContextProvider>
          <PrivateRouter />
        </NotificationsContextProvider>
        </>
      ),
    [isLoading, isAuthorized]
  )
}

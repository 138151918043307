import { createSlice } from '@reduxjs/toolkit'

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: false,
    user: {},
    supplies: [],
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
    setSupplies: (state, { payload }) => {
      state.supplies = payload
    },
  },
})

export const profileReducer = profileSlice.reducer

export const selectUser = (state) => state.profile.user
export const selectSupplies = (state) => state.profile.supplies

export const { setUser, setSupplies, setLoading } = profileSlice.actions

export const setUserData = (data) => (dispatch) => {
  dispatch(setLoading(true))
  dispatch(setUser(data))
  dispatch(setLoading(false))
}

export const setSuppliesData = (data) => (dispatch) => {
  dispatch(setLoading(true))
  dispatch(setSupplies(data))
  dispatch(setLoading(false))
}

export const privateLayoutStyles = (theme) => ({
  root: { height: '100vh', display: 'flex', flexDirection: 'column' },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    minHeight: '80px',
    padding: '0 2%',
  },
  headerLeftContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      order: '2',
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      order: '1',
    },
  },
  headerRightContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      order: '3',
    },
  },
  bodyWrapper: { flex: 1, display: 'inline-block' },
  iconButton: { color: theme.palette.common.black },
  mainArea: { display: 'flex', minHeight: 'calc(100vh - 80px)' },
})

import React from 'react'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { ThemeButton } from '../../../ui/theme-button'

export const NotificationDialog = ({ notification, setNotificationUnseen, close }) => {
  const { formatMessage, formatDate, formatTime } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {notification.cidi_serial}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="body1">
            {notification.dg_ts_insert
              ? formatMessage(
                  { id: 'pages.notifications.modal.date' },
                  {
                    d: formatDate(new Date(notification.dg_ts_insert)),
                    h: formatTime(new Date(notification.dg_ts_insert)),
                  }
                )
              : null}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{notification.mensaje}</Typography>
      </DialogContent>
      <DialogActions>
        {/* { <ThemeButton labelM="small" color="secondary" onClick={close}>
          {formatMessage({ id: 'pages.notifications.modal.delete' })}
        </ThemeButton> 
        <ThemeButton
          labelM="small"
          color="primary"
          onClick={() => {
            setNotificationUnseen(notification.id)
            close()
          }}
        >
          {formatMessage({ id: 'pages.notifications.modal.unseen' })}
        </ThemeButton> */}
        <ThemeButton labelM="small" color="default" onClick={close}>
          {formatMessage({ id: 'pages.notifications.modal.seen' })}
        </ThemeButton>
      </DialogActions>
    </Box>
  )
}

export const signupFormStyles = (theme) => ({
  mandatoryMessageWrapper: {
    margin: '5px 0 0 0',
  },
  link: {
    textDecoration: 'none',
    margin: '0 4px',
  },
  message: {
    color: theme.palette.primary.main,
    fontFamily: 'FSEmeric-Medium',
    cursor: 'pointer',
    '&:hover': { textDecoration: 'underline' },
  },
  checkboxWrapper: {
    display: 'flex',
  },
})

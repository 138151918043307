import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Container, Typography } from '@material-ui/core'

import { HomeDashboard } from '../../../features/home'
import { homeStyles } from './home.styles'

export const HomePage = withStyles(homeStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <div className={classes.titleWrapper}>
          <Typography variant="h4" color="primary" paragraph>
            {formatMessage({ id: 'pages.dashboard.title' })}
          </Typography>
        </div>
        <HomeDashboard />
      </Container>
    </div>
  )
})

import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'

import { useGasAppData } from '../../../gas-applications/hooks/gas-application-data.hook'
import { useModalContext } from '../../../../ui'
import { PendingAppDialog } from '../dialogs'
import { GasAppPanel } from '../gas-app-panel'

export const GasAppDashboardComponent = () => {
  const { applications, cancelPendingApplications } = useGasAppData()
  const {
    actions: { open },
  } = useModalContext()

  useEffect(() => {
    cancelPendingApplications.length > 0 &&
      open({
        closable: false,
        Component: PendingAppDialog,
        data: {
          cancelPendingApplications,
        },
        type: 'centered',
      })
  }, [cancelPendingApplications]) // eslint-disable-line
  return (
    <Box display="flex" flexDirection="column">
      {applications.map((item, i) => (
        <Box key={i} mb={2}>
          <GasAppPanel model={item} isExpanded={applications.length === 1} />
        </Box>
      ))}
    </Box>
  )
}

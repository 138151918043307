import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Chip } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { FileUploaderAgnostic } from '../../../../../ui'
import { attachmentInputStyles } from './attachment-input.styles'

export const AttachmentInput = withStyles(attachmentInputStyles)(
  ({ classes, onChange, name, value }) => {
    const { formatMessage } = useIntl()

    const handleFileUploadError = () => console.log('Error creating file object')

    const handleRemoveFile = () => onChange({ target: { name, value: null } })

    return value ? (
      <Box width="100%" textAlign="left" display="flex" alignItems="center">
        <Chip
          avatar={<AttachFileIcon style={{ backgroundColor: 'transparent' }} />}
          label={value.name}
          color="primary"
          onDelete={handleRemoveFile}
        />
      </Box>
    ) : (
      <FileUploaderAgnostic
        {...{
          name,
          maxSize: 10000000, // 10Mb
          accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
          onSuccess: onChange,
          onError: handleFileUploadError,
        }}
      >
        {() => (
          <div className={classes.btn}>
            <AttachFileIcon className={classes.icon} />
            <Typography variant="body2" color="textSecondary">
              {formatMessage({ id: 'pages.gasApplications.dialogs.requestChanges.fields.2.title' })}
            </Typography>
          </div>
        )}
      </FileUploaderAgnostic>
    )
  }
)

export const prepareAutoFill = (formState, data) => {
  return {
    ...formState,
    nombre: data.usuario.nombre,
    apellidos: data.usuario.apellidos,
    telefono: data.usuario.telefono,
    email: data.usuario.email,
    tipo_identificador: data.usuario.tipo_identificador,
    nif: data.usuario.identificador,
    cod_postal: data.cod_postal,
    municipio: data.municipio,
    direccion: data.direccion,
    numero: data.numero,
    provincia: data.provincia,
  }
}

export const settingsStyles = (theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '60px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
    },
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.success.dark,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

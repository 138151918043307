import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useHistory, Link } from 'react-router-dom'
import { withStyles, Container, Typography, Box, Icon } from '@material-ui/core'
import { toast } from 'react-toastify'

import { useAjaxContext } from '../../../core/ajax'
import { PasswordInput, ThemeButton } from '../../../ui'
import { verifyPwd } from '../utils'
import { pwdRecoveryStyles } from './password-recovery.styles'

export const PasswordRecoveryPage = withStyles(pwdRecoveryStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { id } = useParams()
  const { putById } = useAjaxContext()
  const history = useHistory()

  const [deprecatedRequest, setDeprecatedRequest] = useState(false) // eslint-disable-line
  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)

  useEffect(() => {
    verifyPwd(formState, isValidPassword, setIsValidPassword, setErrorMssg)
  }, [formState]) // eslint-disable-line

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)
    if (isValidPassword) {
      const payload = { new_password: formState['password'] }
      putById('/usuario/new_password', id, payload)
        .then(({ data }) => {
          toast.success(
            <>
              <Icon>check_circle</Icon>
              <Typography
                variant="body1"
                display="inline"
                style={{ verticalAlign: 'super', marginLeft: 8 }}
              >
                {/* {formatMessage({ id: 'pages.login.pwdRecovery.feedback.success' })} */}
                La contraseña se ha cambiado con éxito
              </Typography>
            </>
          )
          history.push('/login')
        })
        .catch(() => {
          console.error('Error requesting change password')
          setErrorMssg(
            'ATENCIÓN: Ya no es posible realizar el cambio de contraseña. La petición ha caducado.'
          )
        })
    }
    setLoading(false)
  }

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <div className={classes.panel}>
          {deprecatedRequest ? (
            <Box textAlign="center" pt={4} pb={4}>
              <Typography variant="h5" paragraph>
                {formatMessage({ id: 'pages.pwdRecovery.notFound' })}
              </Typography>
              <Link to="/login" replace>
                <ThemeButton type="submit" color="secondary" labelM="lg">
                  {formatMessage({ id: 'global.return' })}
                </ThemeButton>
              </Link>
            </Box>
          ) : (
            <form onSubmit={handleSubmitForm}>
              <Box mb={3}>
                <Typography variant="h6" color="primary" paragraph>
                  {formatMessage({ id: 'pages.pwdRecovery.title' })}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ whiteSpace: 'pre-line' }}
                >
                  {formatMessage({ id: 'pages.pwdRecovery.extra' })}
                </Typography>
              </Box>
              <Box mb={4}>
                <PasswordInput
                  value={formState['password']}
                  name="password"
                  title={formatMessage({ id: 'pages.pwdRecovery.form.password.label' })}
                  placeholder={formatMessage({ id: 'pages.pwdRecovery.form.password.placeholder' })}
                  onChange={handleChangeForm}
                  required
                />
                <PasswordInput
                  value={formState['repitPassword']}
                  name="repitPassword"
                  title={formatMessage({ id: 'pages.pwdRecovery.form.repitPassword.label' })}
                  placeholder={formatMessage({
                    id: 'pages.pwdRecovery.form.repitPassword.placeholder',
                  })}
                  onChange={handleChangeForm}
                  required
                />
              </Box>
              {errorMssg && (
                <Box mb={1} bgcolor="grey">
                  <Typography variant="body2" color="error">
                    {errorMssg}
                  </Typography>
                </Box>
              )}
              <ThemeButton type="submit" fullWidth loading={loading}>
                {formatMessage({ id: 'pages.pwdRecovery.form.button.label' })}
              </ThemeButton>
            </form>
          )}
        </div>
      </Box>
    </Container>
  )
})

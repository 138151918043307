export const budgetRequestPanel1Styles = (theme) => ({
  section_1: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  section_1_1: {
    display: 'flex',
    flexGrow: 2,
  },
  section_1_1_1: {
    minWidth: 48,
    minHeight: 48,
  },
  section_1_1_2: {
    margin: '2px 0 0 4px',
  },
  section_1_2: {
    flexGrow: 1,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      marginTop: 10,
    },
  },
})

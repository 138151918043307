import React, { useRef } from 'react'
import { withStyles } from '@material-ui/core'

import { useContainerSize } from '../../../core/utils/useContainerSize'
import { drawerStyles } from './drawer.styles'

export const Drawer = withStyles(drawerStyles)(
  ({ classes, children, float, open, fullyOpened }) => {
    const boxRef = useRef()
    const containerSize = useContainerSize(boxRef)

    let classState = open ? (fullyOpened ? classes.fullyOpened : classes.opened) : classes.closed
    if (float) {
      classState += ' ' + classes.boxFloat
    }

    let headerContent = null
    return (
      <>
        <aside className={classes.box + ' ' + classState} ref={boxRef}>
          <header className={classes.header}>{headerContent}</header>
          {/*When floating, css cannot use parent's size so we get it from a hook and restyle inline*/}
          <main className={classes.main} style={float ? { height: containerSize.height } : null}>
            {open ? children : null}
          </main>
        </aside>
      </>
    )
  }
)

export default Drawer

import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Typography, Divider, Hidden, Box } from '@material-ui/core'

import { GridRow, ExpansionPanel } from '../../../../ui'
import { ReactComponent as ConnectionIcon } from '../../../../resources/images/icon_connection.svg'
import { PANEL_1_GRID_ROW_ITEMS } from '../../constants'
import { budgetRequestPanel1Styles } from './budget-request-panel-1.styles'

export const BudgetRequestPanel1Component = withStyles(budgetRequestPanel1Styles)(
  ({ classes, data }) => {
    const { formatMessage, formatNumber } = useIntl()
    const freeL = data['presupuesto_exterior']['distancia_asumida_nedgia'] || '-'
    const priceL = data['presupuesto_exterior']['precio_metro_tuberia']
      ? formatNumber(data['presupuesto_exterior']['precio_metro_tuberia'], {
          style: 'currency',
          currency: 'EUR',
        })
      : '-'
    const costTotal = parseFloat(
      data['presupuesto_exterior']['precio_instalacion_tuberia'] +
        data['presupuesto_exterior']['precio_instalacion_tuberia_iva']
    )
    const idDisclaimer =
      'pages.budget.view.panel1.detail.description.' + (costTotal === 0 ? 'included' : 'excluded')
    return (
      <ExpansionPanel
        summary={
          <div className={classes.section_1}>
            <div className={classes.section_1_1}>
              <Hidden xsDown className={classes.section_1_1_1}>
                <ConnectionIcon />
              </Hidden>
              <div className={classes.section_1_1_2}>
                <Typography variant="body2">
                  {formatMessage({ id: 'pages.budget.view.panel1.summary.left.title' })}
                </Typography>
                <Typography variant="h5">
                  {formatMessage({ id: 'pages.budget.view.panel1.summary.left.subtitle' })}
                </Typography>
              </div>
            </div>
            <div className={classes.section_1_2}>
              <Typography variant="body2">
                {formatMessage({ id: 'pages.budget.view.panel1.summary.right.title' })}
              </Typography>
              <Typography variant="h5" color="secondary">
                {formatNumber(costTotal, {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </Typography>
            </div>
          </div>
        }
        details={
          <Box>
            <Divider light />
            <Box mt={2}>
              <Typography variant="subtitle1" paragraph>
                {formatMessage({ id: 'pages.budget.view.panel1.detail.title1' })}
              </Typography>
            </Box>
            <GridRow items={PANEL_1_GRID_ROW_ITEMS} data={data} justify="space-between" />
            <Box mt={2}>
              <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                {formatMessage({ id: idDisclaimer }, { freeL: freeL, priceL: priceL })}
              </Typography>
            </Box>
          </Box>
        }
      />
    )
  }
)

import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../../ui'
import { APPLICATION_STATES } from '../../../constants'

export const PaymentProcessor = ({ model, loading }) => {
  const { formatMessage } = useIntl()
  const paymentPending = model['estado'] === APPLICATION_STATES.BUDGET_ACCEPTED.KEY

  return (
    <>
      <ThemeButton
        color="primary"
        type="submit"
        labelM="large"
        disabled={!paymentPending}
        loading={loading}
      >
        {formatMessage({ id: 'pages.gasApplications.wizard.payment.button1.label' })}
      </ThemeButton>
    </>
  )
}

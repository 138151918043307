import { createSlice } from '@reduxjs/toolkit'

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    authData: { isAuthorized: false },
    configData: {},
    isLoading: true,
  },
  reducers: {
    setAuthData: (state, { payload }) => {
      state.authData = payload
      state.isLoading = false
    },
    setConfigData: (state, { payload }) => {
      state.configData = payload
    },
    setGlobalLoading: (state, { payload }) => {
      state.isLoading = payload
    },
  },
})

export const globalReducer = globalSlice.reducer

export const { setAuthData, setConfigData, setGlobalLoading } = globalSlice.actions

import React from 'react'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { ReduxProvider } from '../../core/redux'
import ThemeProvider from '../../core/theme'
import { I18NProvider } from '../../core/i18n'
import { AjaxProvider } from '../../core/ajax'
import { Modal as ModalProvider } from '../../ui/modal'
import { BootstrapRouter } from '../routers/bootstrap.router'
import { OnServerDown } from '../onServerDown/onServerDown.component'
import OnCrash from '../onCrash/onCrash.component'

export const BootstrapComponent = () => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <I18NProvider>
          <AjaxProvider>
            <OnServerDown>
              <OnCrash>
                <ModalProvider>
                  <HashRouter hashType="hashbang">
                    <BootstrapRouter />
                  </HashRouter>
                </ModalProvider>
              </OnCrash>
            </OnServerDown>
          </AjaxProvider>
          <ToastContainer limit={3} />
        </I18NProvider>
      </ThemeProvider>
    </ReduxProvider>
  )
}

import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="33"
      viewBox="0 0 43 33"
      {...props}
    >
      <path d="M38.858.505H3.881C2.014.505.5 2.019.5 3.885v25.156c0 .896.356 1.756.99 2.39.634.634 1.494.99 2.39.99h34.978c.897 0 1.757-.356 2.39-.99.635-.634.991-1.494.991-2.39V3.886c0-.897-.356-1.757-.99-2.39-.634-.635-1.494-.991-2.39-.991zM3.881 2.597h34.977c.711.002 1.287.578 1.29 1.289v3.446H2.582V3.886c.002-.343.139-.672.383-.914.243-.241.573-.377.916-.375zM38.858 30.33H3.881c-.711-.003-1.287-.578-1.29-1.29V13.68h37.566v15.36c-.001.344-.139.672-.382.914-.244.242-.574.377-.917.376zM21.833 18.842H6.473c-.512 0-.927-.415-.927-.927s.415-.928.927-.928h15.36c.513 0 .928.416.928.928s-.415.927-.928.927zm-7.68 4.174h-7.68c-.512 0-.927-.415-.927-.927s.415-.928.927-.928h7.68c.513 0 .928.416.928.928s-.415.927-.928.927z" />
    </SvgIcon>
  )
}

export const INITIAL_STATE = {
  application: null,
  loading: true,
  error: false,
  checked: false,
  cancelled: false,
}

export const REJECTED_STATE = '03'

export const CLIENT_GRID_ROW_ITEMS = [
  {
    label: 'pages.budgetConfirmation.view.fields.name.label',
    valueParsed: (data) =>
      `${data['usuario']['nombre'] || ''} ${data['usuario']['apellidos'] || ''}`,
  },
  {
    label: 'pages.budgetConfirmation.view.fields.id.label',
    valueParsed: (data) => data['usuario']['identificador'],
  },
  {
    label: 'pages.budgetConfirmation.view.fields.mail.label',
    valueParsed: (data) => data['usuario']['email'],
  },
  {
    label: 'pages.budgetConfirmation.view.fields.address.label',
    valueParsed: (data) =>
      `${data['tipo_calle'] ? data['tipo_calle'] : ''} ${
        data['direccion'] ? data['direccion'] : ''
      } ${data['numero'] ? data['numero'] : ''}` +
      `${data['bloque'] ? '-' + data['bloque'] : ''} ` +
      `${data['escalera'] ? '-' + data['escalera'] : ''} ` +
      `${data['piso'] ? data['piso'] : ''} ${data['puerta'] ? data['puerta'] : ''}` +
      '\n' +
      `${data['cod_postal'] ? data['cod_postal'] : ''} ${
        data['municipio'] ? data['municipio'] : ''
      } (${data['provincia'] ? data['provincia'] : ''})`,
    layout: { ...{ xs: 12, sm: 8 } },
  },
  {
    label: 'pages.budgetConfirmation.view.fields.phone.label',
    valueParsed: (data) => data['usuario']['telefono'],
  },
]

export let PANEL_1_GRID_ROW_ITEMS = [
  {
    label: 'pages.budgetConfirmation.view.panel1.fields.grid.item2',
    valueParsed: (data, intl) =>
      intl.formatNumber(data['presupuesto_exterior']['distancia_real'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
  {
    label: 'pages.budgetConfirmation.view.panel1.fields.grid.item3',
    valueParsed: (data, intl) =>
      intl.formatNumber(data['presupuesto_exterior']['ampliacion_asumida_por_cliente'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
]

export const APPLICATION_MOCK = {
  id: 1718,
  dg_name_created_by: 1,
  dg_name_updated_by: 1,
  dg_ts_insert: '2021-06-15T19:56:48.726Z',
  dg_ts_update: '2021-06-15T19:56:50.445Z',
  usuario_id: 156,
  usuario: {
    id: 156,
    dg_name_created_by: 1,
    dg_name_updated_by: 1,
    dg_ts_insert: '2021-01-19T09:58:11.151Z',
    dg_ts_update: '2022-01-20T15:00:53.688Z',
    email: 'jordi.massa@basetis.com',
    nombre: 'Nombre',
    apellidos: null,
    telefono: null,
    username: '_jordi.massa@basetis.com_',
    identificador: '74875278C',
    tipo_identificador: 'dni',
    activo: true,
    idioma: null,
    notificaciones_by: 'email',
    oauth2_id: 242,
    confirmed: true,
    razon_social: null,
    persona: 'J',
    acepta_politica_privacidad: true,
    acepta_comunicaciones_comerciales: true,
    fecha_acepta_politica_privacidad: '2022-01-03T15:15:44.564Z',
    fecha_acepta_comunicaciones_comerciales: '2022-01-20T15:00:53.674Z',
  },
  usuario_grabador: null,
  anexo: '',
  bloque: '',
  cidi: '8492339',
  cod_postal: '36320',
  direccion: 'SUBIDO',
  escalera: '',
  idfinca: null,
  municipio: 'REDONDELA',
  provincia: 'PONTEVEDRA',
  numero: '28',
  piso: 'BJ',
  puerta: '',
  tipo_calle: 'CAMINO',
  tipo_solicitud: 'Cliente',
  tipo_vivienda: 'Unifamiliar',
  camino_solicitud: '01',
  calentador_precio: null,
  caldera_precio: null,
  precio_instalacion_interior: null,
  tarifa_id: null,
  municipio_id: '36045040100',
  provincia_id: '36',
  observaciones: null,
  observaciones_cambio_estado: null,
  cups: 'ES0224050000814378RL',
  cups_editable: false,
  estado_cups: 'SIN RED/IRC',
  estado_certificado_iri: null,
  date_fecha_fin_vigencia_iri: null,
  tipo_gas_potencial: null,
  tipo_gas: null,
  f_peticion_licencia: null,
  f_licencia: null,
  f_inicio_obra: null,
  f_final_ejec_infraestructuras: null,
  fecha_puesta_servicio: null,
  comercializadora: null,
  presupuestos_rechazados: null,
  presupuesto_exterior_id: 958,
  presupuesto_exterior: {
    id: 1626,
    dg_name_created_by: 1,
    dg_name_updated_by: 1,
    dg_ts_insert: '2022-01-27T14:54:21.373Z',
    dg_ts_update: '2022-01-27T14:55:32.830Z',
    d_presupuesto: {
      id: 7689,
      dg_ts_insert: '2022-01-27T14:55:32.796Z',
      dg_ts_update: null,
      nombre: '08-08126000107-202201271555-MONTGAT-12359331M-PR',
      tipo: 'PR',
      cliente_id: null,
      solicitud_id: null,
      inspeccion_id: null,
      extension: 'pdf',
    },
    d_presupuesto_aceptado: null,
    d_comprobante: null,
    d_boletin: null,
    d_croquis: null,
    d_tallo: null,
    distancia_real: 3.96,
    precio_instalacion_tuberia: null,
    precio_instalacion_tuberia_iva: null,
    iva: 21.0,
    distancia_acometida: 3.96,
    acometida: true,
    ampliacion_asumida_por_cliente: null,
    alquiler_contador: 0.58,
    derechos_alta: 63.51,
    derechos_alta_iva: 13.3371,
    derechos_acometida: 106.34,
    canon_mensual: 0.0,
    periodicidad_canon: 'No',
    total_costes_fijos_sin_iva: 64.09,
    total_costes_fijos_con_iva: 77.55,
    fecha_solicitud: '2022-01-27T14:55:32.804Z',
    fecha_caducidad: '2022-07-26T14:55:32.804Z',
    fecha_realizacion: null,
    fecha_confirmacion: null,
    fecha_pendiente_boletin: null,
    estado: '10',
    tipo: null,
    encuesta: null,
    distancia_asumida_nedgia: 6.0,
    precio_metro_tuberia: 104.2,
    iban: 'ES49 2100 0900 9902 1151 6835',
    plazo_dias_obras: '118',
  },
  presupuesto_interior_id: null,
  presupuesto_interior: null,
  fecha_peticion: '2021-06-15T19:48:07.788Z',
  fecha_solicitud: '2021-06-15T19:56:48.721Z',
  scr_id: 1176,
  cod_solicitud_zeus: null,
  cod_expediente_zeus: null,
  estado_zeus: null,
  fecha_solicitud_zeus: null,
  estado_pago_oferta: null,
  estado_certificacion_oferta: null,
  motivo_anulacion_zeus: null,
  scr_enviable: true,
  enviar_scr: false,
  estado: '10',
  ip: 'localhost',
  navegador: 'Chrome 91.0447277, Windows 10',
  gestor: null,
  gestor_id: null,
  solicitud_id: '8492339-002',
  motivo_anulacion_cliente: null,
  motivo_anulacion_gestor: null,
  motivo_anulacion_instalador: null,
  empresa_id: null,
  empresa: null,
  empresa_ca_id: null,
  empresa_ca: null,
  instalador_id: null,
  instalador: null,
  certificado_IRG3: {
    id: null,
    fecha_documento_IRG3: null,
    d_IRG3_id: null,
    d_IRG3: null,
    fecha_creacion: null,
    fecha_modificacion: null,
    fecha_certificado: null,
    certificado_IRG3_id: null,
    IRG3_validado: null,
    fecha_pendiente_IRG3: null,
    estado: null,
    observaciones: null,
    observaciones_cambio_estado: null,
  },
  certificado_IRG1: {
    id: null,
    fecha_documento_IRG1: null,
    d_IRG1_id: null,
    d_IRG1: null,
    fecha_creacion: null,
    fecha_modificacion: null,
    fecha_certificado: null,
    certificado_IRG1_id: null,
    estado: null,
    observaciones: null,
    observaciones_cambio_estado: null,
  },
  certificado_IRG2: {
    id: null,
    fecha_documento_IRG2: null,
    d_IRG2_id: null,
    d_IRG2: null,
    fecha_creacion: null,
    fecha_modificacion: null,
    fecha_certificado: null,
    certificado_IRG2_id: null,
    estado: null,
    observaciones: null,
    observaciones_cambio_estado: null,
  },
  certificado_modificacion: {
    id: null,
    fecha_documento_modificacion: null,
    d_CM_id: null,
    d_CM: null,
    fecha_creacion: null,
    fecha_modificacion: null,
    fecha_certificado: null,
    certificado_modificacion_id: null,
    estado: null,
    observaciones: null,
    observaciones_cambio_estado: null,
  },
  ultimo_certificado: null,
  captador_id: null,
  agua_caliente: null,
  calefaccion: null,
  cocina: null,
  certificado: null,
  actual_acs: null,
  num_banos_acs: null,
  actual_cal: null,
  metros_cal: null,
  personas_acs: null,
  estancias_cal: null,
  termostato_cal: null,
  toallero_cal: null,
  plantas_cal: null,
  actual_cocina: null,
  instalar_cocina: null,
  ubi_caldera: null,
  nueva_caldera: null,
  iri_id: null,
  tipo_termostato: null,
  num_termostatos: null,
  caracteristicas_calentador: null,
  caracteristicas_caldera: null,
  mercado_id: null,
  financiacion: null,
  oferta_id: null,
  campana_id: null,
  campana: null,
  campana_op_id: null,
  campana_op: null,
  radiador_cal: null,
  caldera_acs: null,
  gama_caldera: null,
  calentador_acs: null,
  ubi_calentador: null,
  nuevo_calentador: null,
  d_AI: null,
  d_CC: null,
  d_CP: null,
  d_CR: null,
  d_DN: null,
  d_CH: null,
  d_SG: null,
  d_AR: null,
  d_FC1: null,
  d_FC2: null,
  d_FC3: null,
  d_CA: null,
  d_CB: null,
  d_CCEE: null,
  d_RC: null,
  d_EF: null,
  d_tallo_i: null,
  d_croquis_i: null,
  d_SP: null,
  d_SGP: null,
  d_EC: null,
  d_CPI: null,
  d_AC: null,
  sociedad: 'NEDGIA GALICIA, S.A.',
  mercado: 'SP',
  mercado_modificado: false,
  condicionante_obras: null,
  rango_presion: '4 BAR',
  rango_presion_potenciales: '4 BAR',
  num_cedula_habitabilidad: null,
  subtipo_solicitud: 'scr',
  num_suministros_finca_potenciales: null,
  num_suministros_finca_interesados: null,
  usuario_sspp_id: null,
  usuario_sir_id: null,
  usuario_sir: null,
  presupuesto_sir_id: null,
  presupuesto_sir: null,
  name_sociedad_eps: null,
  gremio_id: null,
  gremio_name: null,
  gremio: null,
  sstt_id: null,
  sstt: null,
  associated_certificates: [],
  contacto_instalador: false,
  contacto_instalador_fecha: null,
  contacto_instalador_mail_sent: false,
  puntos_suministo_potenciales: 1,
  puntos_suministo_interesados: 1,
  num_encargo_goda: null,
  num_gor_icaro: null,
  fecha_ultimo_cambio_estado: '2021-06-15T19:56:48.721Z',
  observaciones_cliente: null,
  mail_valoracion_sent: false,
  valoracion_id: null,
  valoracion: null,
  aceptacion_presupuesto_instalador: null,
  fecha_aceptacion_presupuesto_instalador: null,
  alerta_contacto_instalador: true,
  alerta_aceptar_presupuesto_instalador: true,
  estado_anterior: null,
  autor_ultimo_cambio_estado: null,
  encuesta: null,
  normalizada_duplicada: false,
  codigo_financiacion: null,
  uso: 'Residencial',
  potencia: null,
  referencia_catastral: null,
  numero_tarifa_aplicada: null,
  consumo: null,
  ubi_contador: null,
  prop_contador: null,
  prop_irc_armario_reg: null,
  caso_caldera_centr: null,
  old_croquis: [],
  estado_sugerido_avance: null,
  distrito: null,
  latitud: null,
  longitud: null,
  originally_auto_budget: false,
  l4_exception: false,
  licencia: {
    fecha_solicitud_licencia: null,
    fecha_prevision_licencia: null,
    fecha_recepcion_licencia: null,
  },
  seguimiento_obras: {
    fecha_adjudicacion_obra: null,
    fecha_planificacion_obra: null,
    fecha_puesta_gas_obra: null,
  },
  puesta_servicio: {
    fecha_visita_eop: null,
    observaciones_ultima_visita: null,
    anomalias_observadas: null,
  },
  iban: null,
  titular_iban: '',
  motivo_no_captabilidad: null,
  anulacion_hse: false,
  anulacion_zeus: false,
  camino_normalizada_instalador: null,
  validada_gestor: false,
  reasignable: false,
}

import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Backdrop, Box, CircularProgress } from '@material-ui/core'

import { ModalContext } from '../../../../ui/modal/contexts'
import { ViewPDFDialog } from '../../../../ui/view-pdf-dialog'
import { feedback } from '../../../../core/feedback'
import { useBudgetConfirmationContext } from '../../contexts'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

export const BudgetMandatoryMssgComponent = ({ classes }) => {
  const { formatMessage } = useIntl()
  const { fetchBudgetFile, changeChecked } = useBudgetConfirmationContext()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const [isLoading, setLoading] = useState(false)

  const handleChangeChecked = (bool) => changeChecked(!bool)

  const handleShowFile = () => {
    setLoading(true)
    fetchBudgetFile()
      .then((data) => {
        const base64Data = data['documento'].split("'")[1]
        const file = `data:application/pdf;base64,${base64Data}`
        setLoading(false)
        open({
          Component: ViewPDFDialog,
          data: { file, setDisabled: handleChangeChecked },
          type: 'fullScreen',
          closable: false,
        })
      })
      .catch(() => {
        setLoading(false)
        feedback('error', formatMessage({ id: 'pages.budgetConfirmation.feedback.2' }))
      })
    setLoading(true)
  }

  return (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography variant="body2">
          {formatMessage({ id: 'pages.budgetConfirmation.panel.mandatoryMssg1' })}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" ml={2}>
        <PictureAsPdfIcon onClick={handleShowFile} />
      </Box>
      <Backdrop style={{ zIndex: 999 }} open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Box>
  )
}

export const budgetStepStyles = (theme) => ({
  documentLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    margin: '0 4px',
    color: theme.palette.primary.main,
  },
  downloadLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  pdfDocumentWrapper: {
    position: 'relative',
  },
  pdfButtonsWrapper: {
    position: 'sticky',
    display: 'flex',
    justifyContent: 'center',
  },
  pdfButton: {
    margin: '0 10px',
  },
  marginRight: {
    marginRight: 4,
  },
})

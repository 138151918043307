const { REACT_APP_WP_URL } = process.env

export const URL_CANCEL_LINK = `${REACT_APP_WP_URL}/no-me-interesa`

export const URL_NEDGIA_LINK = 'https://www.nedgia.es/'

export const CLIENT_GRID_ROW_ITEMS = [
  {
    label: 'pages.budget.view.detail.name.label',
    valueParsed: (data) => `${data['usuario']['nombre']} ${data['usuario']['apellidos']}`,
  },
  {
    label: 'pages.budget.view.detail.id.label',
    valueParsed: (data) => data['usuario']['identificador'],
  },
  {
    label: 'pages.budget.view.detail.mail.label',
    valueParsed: (data) => data['usuario']['email'],
  },
  {
    label: 'pages.budget.view.detail.address.label',
    valueParsed: (data) =>
      `${data['tipo_calle'] ? data['tipo_calle'] : ''} ${
        data['direccion'] ? data['direccion'] : ''
      } ${data['numero'] ? data['numero'] : ''}` +
      `${data['bloque'] ? '-' + data['bloque'] : ''} ` +
      `${data['escalera'] ? '-' + data['escalera'] : ''} ` +
      `${data['piso'] ? data['piso'] : ''} ${data['puerta'] ? data['puerta'] : ''}` +
      '\n' +
      `${data['cod_postal'] ? data['cod_postal'] : ''} ${
        data['municipio'] ? data['municipio'] : ''
      } (${data['provincia'] ? data['provincia'] : ''})`,
    multiline: true,
  },
  {
    label: 'pages.budget.view.detail.phone.label',
    valueParsed: (data) => data['usuario']['telefono'],
  },
]

export let PANEL_1_GRID_ROW_ITEMS = [
  {
    label: 'pages.budget.view.panel1.detail.grid.item2',
    valueParsed: (data, intl) =>
      intl.formatNumber(data['presupuesto_exterior']['distancia_real'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
  {
    label: 'pages.budget.view.panel1.detail.grid.item3',
    valueParsed: (data, intl) =>
      intl.formatNumber(data['presupuesto_exterior']['ampliacion_asumida_por_cliente'], {
        style: 'unit',
        unit: 'meter',
      }),
  },
]

import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Icon } from '@material-ui/core'
import { toast } from 'react-toastify'

import { TextInput, ThemeButton } from '../../../ui'
import { useAjaxContext } from '../../../core/ajax'

export const PwdRecoveryDialogComponent = ({ close }) => {
  const { formatMessage } = useIntl()
  const { post } = useAjaxContext()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)
    const payload = { email: formState['email'], area: 'clientes' }
    post('/usuario/recuperar_password', payload)
      .then(({ data }) => {
        toast.success(
          <>
            <Icon>check_circle</Icon>
            <Typography
              variant="body1"
              display="inline"
              style={{ verticalAlign: 'super', marginLeft: 8 }}
            >
              {formatMessage({ id: 'pages.login.pwdRecovery.feedback.success' })}
            </Typography>
          </>
        )
        setLoading(false)
        close()
      })
      .catch(() => {
        console.error('Error recovery password')
        setLoading(false)
        setErrorMssg('El correo introducido no es válido')
      })
  }

  return (
    <Box p={4}>
      <Box mb={4} textAlign="center">
        <Typography variant="h5" color="primary">
          {formatMessage({ id: 'pages.login.pwdRecovery.title' })}
        </Typography>
      </Box>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <TextInput
            type="email"
            required
            value={formState['email']}
            name="email"
            title={formatMessage({ id: 'pages.login.pwdRecovery.form.email.label' })}
            placeholder={formatMessage({ id: 'pages.login.pwdRecovery.form.email.placeholder' })}
            onChange={handleChangeForm}
          />
        </Box>
        {errorMssg && (
          <Box mb={1} bgcolor="grey">
            <Typography variant="body2" color="error">
              {errorMssg}
            </Typography>
          </Box>
        )}
        <ThemeButton type="submit" fullWidth loading={loading} disabled={loading}>
          {formatMessage({ id: 'pages.login.pwdRecovery.form.button.1.label' })}
        </ThemeButton>
        <ThemeButton
          variant="outlined"
          fullWidth
          loading={loading}
          disabled={loading}
          onClick={close}
        >
          {formatMessage({ id: 'pages.login.pwdRecovery.form.button.2.label' })}
        </ThemeButton>
      </form>
    </Box>
  )
}

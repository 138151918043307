import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { InfoOutlined } from '@material-ui/icons'
import foto_tallo from '../../../../../resources/images/foto_tallo_ejemplo.png'
import { Link } from '@material-ui/core'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: 'block',
  },
}))(MuiDialogActions)

export default function BulletinEventsTalloExampleDialogComponent() {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = (e) => {
    e.preventDefault()
    setOpen(true)
  }
  const handleClose = (e) => {
    e.preventDefault()
    setOpen(false)
  }

  return (
    <div>
      <Link href="#" onClick={handleClickOpen}>Ver ejemplo</Link>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={'md'}>
        <DialogContent>
          <img src={foto_tallo} style={{ width: '100%', height: 'auto' }} alt="Ejemplo Foto Tallo" />
        </DialogContent>
        <DialogActions>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button autoFocus onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </div>

        </DialogActions>
      </Dialog>
    </div>
  )
}
import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { withStyles, Container, Typography, Icon, Box, CircularProgress } from '@material-ui/core'
import { parse } from 'qs'

import { ThemeButton } from '../../../ui'
import { usePaymentStep } from '../../gas-applications/components/gas-app-steps/payment/payment-step.hook'
import { paymentResultStyles } from './payment-result.styles'

export const PaymentResult = withStyles(paymentResultStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const { validatePayment } = usePaymentStep()

  const [isLoading, setIsLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const sociedadId = location.pathname.slice(
      location.pathname.length - 4,
      location.pathname.length
    )
    if (location.search) {
      const params = parse(location.search.split('?')[1])
      const payload = {
        Ds_Signature: params.Ds_Signature,
        Ds_MerchantParameters: params.Ds_MerchantParameters,
      }
      validatePayment(payload, sociedadId)
        .then((response) => {
          if (response.status === 200) {
            setSuccess(true)
          }
          setMessage(response.data.message)
          setIsLoading(false)
        })
        .catch(() => {
          setMessage(formatMessage({ id: 'pages.paymentResult.message.3' }))
          setIsLoading(false)
        })
    }
  }, []) // eslint-disable-line

  return (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      {isLoading ? (
        <CircularProgress size={40} />
      ) : (
        <Container maxWidth="md">
          <div className={classes.panel}>
            <Icon color={success ? 'primary' : 'secondary'} className={classes.icon}>
              {success ? 'check_circle' : 'error'}
            </Icon>
            <Box height="100%" alignItems="center">
              <Typography variant="h4" paragraph>
                {formatMessage({ id: `pages.paymentResult.message.${success ? '1' : '2'}` })}
              </Typography>
              {!success && message ? (
                <Typography variant="h6" paragraph>
                  {message}
                </Typography>
              ) : null}
            </Box>
            <Link className={classes.button} to="/gas-applications" replace>
              <ThemeButton type="submit" color="secondary">
                {formatMessage({ id: 'global.continue' })}
              </ThemeButton>
            </Link>
          </div>
        </Container>
      )}
    </Box>
  )
})

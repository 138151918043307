import React from 'react'
import { InputAdornment } from '@material-ui/core'
import { APPLICATION_STATES } from '../../../constants'

export const PAYMENT_STEP_COMPLETED_STATES = {
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_REJECTED.KEY]: true,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]: true,
  [APPLICATION_STATES.FINISHED.KEY]: true,
}

export const PAYMENT_NEEDED_STATES = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
}

export const CAN_SELECT_INSTALLER = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.VALIDATION_PENDING.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
}

export const PAYMENT_FILE_SIZE = 10000000

export const BILLING_DATA_FORM_FIELDS = ({ formState, onChange, fm }) => ({
  NOMBRE: {
    required: true,
    name: 'nombre',
    value: formState.nombre,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.name.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.name.placeholder' }),
    onChange,
  },
  APELLIDOS: {
    required: true,
    name: 'apellidos',
    value: formState.apellidos,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.apellidos.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.apellidos.placeholder' }),
    onChange,
  },
  TELEFONO: {
    required: true,
    name: 'telefono',
    value: formState.telefono,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.telefono.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.telefono.title' }),
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
    InputProps: {
      startAdornment: <InputAdornment position="start">+34</InputAdornment>,
    },
  },
  EMAIL: {
    required: true,
    name: 'email',
    value: formState.email,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.email.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.email.placeholder' }),
    onChange,
    inputProps: { type: 'email' },
  },
  NIF: {
    required: true,
    name: 'nif',
    value: formState.nif,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.identificador.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.identificador.placeholder' }),
    onChange,
  },
  CODIGO_POSTAL: {
    required: true,
    name: 'cod_postal',
    value: formState.cod_postal,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.codigo_postal.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.codigo_postal.placeholder' }),
    onChange,
    inputProps: {
      pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
      maxLength: 5,
    },
  },
  MUNICIPIO: {
    required: true,
    name: 'municipio',
    value: formState.municipio,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.municipio.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.municipio.placeholder' }),
    onChange,
  },
  DIRECCION: {
    required: true,
    name: 'direccion',
    value: formState.direccion,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.via.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.via.placeholder' }),
    onChange,
  },
  NUMERO: {
    required: true,
    name: 'numero',
    value: formState.numero,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.numero.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.numero.placeholder' }),
    onChange,
  },
  PROVINCIA: {
    required: true,
    name: 'provincia',
    value: formState.provincia,
    title: fm({ id: 'pages.gasApplications.wizard.payment.form.provincia.title' }),
    placeholder: fm({ id: 'pages.gasApplications.wizard.payment.form.provincia.placeholder' }),
    onChange,
  },
})

import React from 'react'
import { withStyles, Button, CircularProgress, Box, Typography, Tooltip } from '@material-ui/core'

import { themeButtonStyles } from './theme-button.styles'

export const ThemeButton = withStyles(themeButtonStyles)(
  ({
    classes,
    variant = 'contained',
    color = 'primary',
    labelM = 'small',
    children,
    loading = false,
    ...props
  }) => {
    const SimpleButton = () => (
      <Button
        {...props}
        classes={classes}
        variant={variant}
        color={color}
        disabled={props.disabled || loading}
        disableElevation
      >
        {loading ? (
          <CircularProgress size={30} color={color} />
        ) : (
          <Box display="flex" alignItems="center">
            {props.icon}
            {children}
          </Box>
        )}
      </Button>
    )

    return (
      <Box>
        {props.tooltip ? (
          <Tooltip title={props.tooltip} arrow placement="top">
            <SimpleButton />
          </Tooltip>
        ) : (
          <SimpleButton />
        )}
        {props.extra && (
          <Box mt={-2.5}>
            <Typography variant="caption" color="textSecondary">
              {props.extra}
            </Typography>
          </Box>
        )}
      </Box>
    )
  }
)

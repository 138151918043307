const NIF_SEQUENCE = 'TRWAGMYFPDXBNJZSQVHLCKE'
const CIF_ONLY_NUMBERS = 'ABEH'
const CIF_ONLY_LETTERS = 'KPQS'
const CIF_SEQUENCE = 'JABCDEFGHI'

const NIF_REGEX = new RegExp('^(\\d{8})[' + NIF_SEQUENCE + ']$')
const NIE_REGEX = new RegExp('^[XYZ](\\d{7})[' + NIF_SEQUENCE + ']$')
const CIF_REGEX = new RegExp('^[A-HJ-NP-SUVW](\\d{7})[0-9A-J]$')

export const isValidNIF = (document) => {
  const allup = document.toUpperCase()
  if (!NIF_REGEX.test(allup)) {
    return false
  }
  return allup[8] === NIF_SEQUENCE[parseInt(allup.slice(0, 8)) % 23]
}

export const isValidNIE = (document) => {
  const allup = document.toUpperCase()
  if (!NIE_REGEX.test(allup)) {
    return false
  }
  const prefix = {
    X: '0',
    Y: '1',
    Z: '2',
  }
  const toValidate = prefix[allup[0]] + allup.substring(1, 9)
  return toValidate[8] === NIF_SEQUENCE[parseInt(toValidate.slice(0, 8)) % 23]
}

export const isValidCIF = (document) => {
  const allup = document.toUpperCase()
  if (!CIF_REGEX.test(allup)) {
    return false
  }

  //Split code into its three parts
  const prefix = allup[0]
  const number = allup.slice(1, 8)
  const control = allup[8]

  //Compute reference value/position
  const sum =
    number.split('').reduce((sum, xifra, i) => {
      if (i % 2 === 0) {
        const doble = parseInt(xifra) * 2
        return sum + (doble > 9 ? doble - 9 : doble)
      }
      return sum + parseInt(xifra)
    }, 0) % 10
  const ref = sum > 0 ? 10 - sum : sum

  //Check itself:
  return (
    (CIF_ONLY_NUMBERS.indexOf(prefix) === -1 && CIF_SEQUENCE[ref] === control) ||
    (CIF_ONLY_LETTERS.indexOf(prefix) === -1 && '' + ref === control)
  )
}

export const getTypeOfDocument = (document) => {
  if (isValidNIF(document) || isValidCIF(document)) {
    return 'NIF'
  } else if (isValidNIE(document)) {
    return 'NIE'
  }
}

export const cancelBtnStyles = (theme) => ({
  root: {
    height: '48px',
    padding: 0,
    minWidth: '40px',
    backgroundColor: theme.palette.grey.A100,
    color: theme.palette.grey.A400,
    '&:hover': { backgroundColor: theme.palette.error.light, color: theme.palette.common.white },
  },
})

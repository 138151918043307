import React from 'react'
import { useSelector } from 'react-redux'
import { withStyles, Container } from '@material-ui/core'

import { BudgetRequestAuthComponent } from '../../../features/budget-request'
import { BudgetRequestViewComponent } from '../../../features/budget-request'
import { selectBudgetRequestData } from '../../../features/budget-request'
import { budgetRequestStyles } from './budget-request.styles'

export const BudgetRequestPage = withStyles(budgetRequestStyles)(({ classes }) => {
  const budgetData = useSelector(selectBudgetRequestData)

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <div className={classes.panel}>
          {Object.keys(budgetData).length === 0 ? (
            <BudgetRequestAuthComponent />
          ) : (
            <BudgetRequestViewComponent data={budgetData} />
          )}
        </div>
      </Container>
    </div>
  )
})

import { APPLICATION_STATES } from '../../constants'

export const PROCESSING_STATES = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
}

export const NORMALIZATION_STATES = {
  [APPLICATION_STATES.NORMALIZATION.KEY]: true,
  [APPLICATION_STATES.NORMALIZATION_PROCESSED.KEY]: true,
}

export const CANCELLING_STATES = {
  [APPLICATION_STATES.REJECTED.KEY]: true,
  [APPLICATION_STATES.REJECTED_2.KEY]: true,
  [APPLICATION_STATES.REJECTED_NORMALIZATION.KEY]: true,
}

export const CLIENT_PENDING_CANCEL = {
  [APPLICATION_STATES.CANCEL_PENDING.KEY]: true,
}

export const CAN_SELECT_INSTALLER = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.VALIDATION_PENDING.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
}

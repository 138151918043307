import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { ConfirmationDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'

export const useDocumentDialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)
  const warnPassport = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.signup.passportDialog.title' }),
        text: formatMessage({ id: 'pages.signup.passportDialog.text' }),
        yesText: formatMessage({ id: 'pages.signup.passportDialog.yes' }),
        noText: formatMessage({ id: 'pages.signup.passportDialog.no' }),
        yesAction: callback,
      },
      type: 'centered',
    })
  }

  return {
    warnPassport,
  }
}

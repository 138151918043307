import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Divider } from '@material-ui/core'
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { expansionPanelStyles } from './expansion-panel.styles'

export const ExpansionPanelComponent = withStyles(expansionPanelStyles)(
  ({
    classes,
    triggerPosition = 'left',
    isExpanded = false,
    inactive,
    onExpand = false,
    ...props
  }) => {
    const { formatMessage } = useIntl()

    const [expanded, setExpanded] = useState(isExpanded)

    const handleExpand = () => {
      const newState = !expanded
      props.details && setExpanded(newState)
      if (typeof onExpand === 'function') onExpand(newState)
    }

    return (
      <>
        <Accordion className={classes.accordion} expanded={expanded}>
          <AccordionSummary
            className={classes.accordionSummary}
            style={inactive ? { cursor: 'default' } : {}}
          >
            <Box mb={2}>{props.summary}</Box>
            {props.details && (
              <>
                <Divider light />
                <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                  {props.summaryExtra && <Box flexGrow={1}>{props.summaryExtra}</Box>}
                  <Box flexGrow={1} textAlign={triggerPosition} onClick={handleExpand}>
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.accordionToggler}
                    >
                      {formatMessage({
                        id: `pages.budget.view.panel1.summary.expanded.${expanded.toString()}`,
                      })}
                      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </AccordionSummary>
          <AccordionDetails>{props.details}</AccordionDetails>
        </Accordion>
        {props.footer && (
          <Box ml={2} mr={2}>
            {props.footer}
          </Box>
        )}
      </>
    )
  }
)

import React, { useState, createContext, useContext } from 'react'

import { useBudgetConfirmationActions } from '../hooks'
import { INITIAL_STATE } from '../constants'

const BudgetConfirmationContext = createContext()

export const BudgetConfirmationProvider = ({ children }) => {
  const [state, setState] = useState(INITIAL_STATE)

  const actions = useBudgetConfirmationActions(state, setState)

  return (
    <BudgetConfirmationContext.Provider value={{ ...state, ...actions }}>
      {children}
    </BudgetConfirmationContext.Provider>
  )
}

export const useBudgetConfirmationContext = () => useContext(BudgetConfirmationContext)

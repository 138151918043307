export const USER_GRID_ROW_ITEMS = [
  {
    label: 'pages.profile.user.fields.name',
    valueParsed: (data) => `${data['nombre']} ${data['apellidos']}`,
  },
  {
    label: 'pages.profile.user.fields.id',
    value: 'identificador',
  },
  {
    label: 'pages.profile.user.fields.email',
    value: 'email',
  },
  {
    label: 'pages.profile.user.fields.phone',
    value: 'telefono',
  },
  {
    label: 'pages.profile.user.fields.language',
    value: 'idioma',
  },
  {
    label: 'pages.profile.user.fields.comunicaciones_comerciales',
    valueParsed: (data) => data['recibe_emails_comunicados'] ? 'Sí' : 'No',
  }
]

export const SUPPLY_GRID_ROW_ITEMS = []

import React, { useState } from 'react'
import { encodeToBase64, isAcceptedFormat } from '../utils'
import { DEFAULT_FILE_SIZE } from '../constants'

export const FileUploaderAgnostic = ({
  idName,
  name,
  accept,
  onSuccess,
  onError,
  disabled = false,
  maxSize = DEFAULT_FILE_SIZE,
  children,
}) => {
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState(false)

  const handleChangeUpload = async (e) => {
    setLoading(true)
    const file = e.target.files[0]
    if (file) {
      if (accept && accept.length > 0 && !isAcceptedFormat(file.name, accept)) {
        setLoading(false)
        return onError({ mssg: 'Archivo con formato no válido' })
      } else if (file.size > maxSize) {
        setLoading(false)
        return onError({ mssg: 'Excede el tamaño máximo' })
      } else {
        setFileName(file.name)
        const fileBase64 = await encodeToBase64(file, true)
        setLoading(false)
        return onSuccess({
          target: {
            name,
            value: { data: fileBase64, name: file.name, type: file.type.split('/')[1] },
          },
        })
      }
    }
    setLoading(false)
  }
  const nameId = `file-upload-${idName || name}`
  return (
    <div>
      <input
        id={nameId}
        name={name}
        type="file"
        style={{ display: 'none' }}
        accept={accept.join(',')}
        onChange={handleChangeUpload}
        disabled={disabled}
      />
      <label htmlFor={nameId}>{children({ loading, fileName })}</label>
    </div>
  )
}

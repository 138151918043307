import React, { useMemo } from 'react'
import {
  withStyles,
  TextField,
  FormControl,
  FormHelperText,
  Tooltip,
  Icon,
  Typography,
  MenuItem,
} from '@material-ui/core'

import { textInputStyles } from './text-input.styles'

const ROWS_MAX = 5

export const TextInput = React.memo(
  withStyles(textInputStyles)(
    ({ classes, type = 'text', readOnly = false, rowsMax = ROWS_MAX, ...props }) => {
      const InputProps = useMemo(() => {
        return { className: classes.input, ...props.InputProps }
      }, [classes.input, props.InputProps])
      const tooltip = useMemo(() => {
        return props.tooltip ? (
          <span style={{ fontSize: 16, marginLeft: 4 }}>
            <Tooltip
              placement="top"
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {props.tooltip}
                </Typography>
              }
            >
              <Icon fontSize="inherit">help_outline</Icon>
            </Tooltip>
          </span>
        ) : null
      }, [props.tooltip])

      const typographyVariant = props.fontSize === 'small' ? '2' : '1'
      return (
        <FormControl className={classes.root}>
          {props.title ? (
            <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
              {props.title + (tooltip ? tooltip : '')}
            </Typography>
          ) : null}

          {readOnly ? (
            <Typography
              variant={`body${typographyVariant}`}
              color="textSecondary"
              style={{ whiteSpace: 'pre-line' }}
            >
              {props.value ? props.value + (props.units ? ' ' + props.units : '') : '--'}
            </Typography>
          ) : (
            <>
              <TextField
                rowsMax={rowsMax}
                {...props}
                type={type}
                variant="outlined"
                value={props.value || ''}
                placeholder={props.placeholder}
                onChange={props.onChange}
                InputProps={InputProps}
                // autoComplete="off"
                // inputProps={{ maxLength: props.maxLength }}
                // InputProps={{ className: classes.input, ...props.InputProps }}
              >
                {props.values
                  ? props.values.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
              <FormHelperText id="my-helper-text" error={props.error}>
                {props.error ? props.errorMessage : props.helpMessage}
              </FormHelperText>
            </>
          )}
        </FormControl>
      )
    }
  )
)

import React, { useContext, Fragment } from 'react'
import { Typography, IconButton } from '@material-ui/core'
import { Clear, ErrorOutline } from '@material-ui/icons'
import { useIntl } from 'react-intl'

import { Drawer } from '../../../ui/drawer'
import { NotificationCard } from '../../../ui/notificationCard'

import { useWidth } from '../../../core/utils'
import { NotificationsContext } from '../contexts'
import { CARD_CHARACTER_TOP } from '../constants'

export const NotificationDrawer = () => {
  const { formatDate, formatMessage } = useIntl()
  const screenWidth = useWidth()

  const {
    notificationsData,
    notificationsShow,
    showNotification,
    setNotificationSeen,
    toggleNotificationsShow,
  } = useContext(NotificationsContext)

  const notificationTypes = ['notif']
  const notificationStructure = {}
  notificationTypes.map((type) => {
    notificationStructure[type] = {
      id: type,
      title: (llista) =>
        formatMessage({ id: 'pages.notifications.' + type + '.title' }, { n: llista.length }),
      subtitle: (llista) => {
        const n = llista.filter((el) => !el.visto).length
        return formatMessage(
          {
            id:
              'pages.notifications.' + type + '.subtitle.' + (!n ? 'none' : n > 1 ? 'some' : 'one'),
          },
          { n: n }
        )
      },
      cardAttributes: (item) => {
        const buttons = [
          {
            key: 'show',
            color: 'primary',
            text: formatMessage({ id: 'pages.notifications.buttons.show' }),
            action: () => showNotification(item.id),
          },
        ]
        if (!item.visto) {
          buttons.push({
            key: 'del',
            color: 'error',
            text: formatMessage({ id: 'pages.notifications.buttons.seen' }),
            action: () => setNotificationSeen(item.id),
          })
        }
        return {
          key: type + '_' + item.id,
          date: item.dg_ts_insert ? formatDate(new Date(item.dg_ts_insert)) : null,
          title: item.cidi_serial,
          text:
            item.mensaje.length < CARD_CHARACTER_TOP
              ? item.mensaje
              : item.mensaje
                  .substr(0, CARD_CHARACTER_TOP - 3)
                  .split(' ')
                  .slice(0, -1)
                  .join(' ') + '\u2026',
          extraClass: !item.visto ? 'pending' : null,
          Icon: !item.visto ? ErrorOutline : null,
          buttons: buttons,
        }
      },
    }
    return null
  })

  const notificationList = notificationTypes.map((type) => ({
    key: type,
    source: notificationsData,
    config: notificationStructure[type],
  }))

  return (
    <Drawer
      title=""
      open={notificationsShow}
      float={screenWidth === 'sm' || screenWidth === 'xs' ? true : false}
      fullyOpened={screenWidth === 'xs'}
    >
      <IconButton onClick={toggleNotificationsShow} style={{ float: 'right' }}>
        <Clear />
      </IconButton>
      {notificationList.map((type) => {
        return (
          <Fragment key={type.key}>
            <Typography variant="h5" color="primary">
              {type.config.title(type.source)}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {type.config.subtitle(type.source)}
            </Typography>
            {type.source.map((item) => {
              return <NotificationCard {...type.config.cardAttributes(item)} />
            })}
          </Fragment>
        )
      })}
    </Drawer>
  )
}

const styledBy = (property, mapping) => (props) => mapping[props[property]]

export const paymentStepStyles = (theme) => ({
  downloadLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  optionIcon: {
    fontSize: 40,
  },
  marginRight: {
    marginRight: 4,
  },
})

export const optionButtonStyles = (theme) => ({
  root: {
    display: 'block',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
    padding: 8,
    minWidth: 200,
    textAlign: 'center',
    lineHeight: 1,
    '&:hover': {
      // boxShadow: '0 0 0 0.2rem #0000000D',
      backgroundColor: styledBy('active', {
        true: theme.palette.primary.main,
        false: theme.palette.common.white,
      }),
    },
    backgroundColor: styledBy('active', {
      true: theme.palette.primary.main,
      false: theme.palette.common.white,
    }),
    color: styledBy('active', {
      false: theme.palette.primary.main,
      true: theme.palette.common.white,
    }),
    boxShadow: styledBy('active', {
      false: 'none',
      true: '0 0 0 0.2rem #0045711A',
    }),
  },
})

import React from 'react'
import { withStyles, Typography, Card, CardContent, CardActions } from '@material-ui/core'
import { Button, IconButton } from '@material-ui/core'

import { notificationCardStyles } from './notificationCard.styles'

export const NotificationCard = withStyles(notificationCardStyles)(
  ({ classes, date, title, text, Icon, iconAction, buttons, extraClass }) => {
    return (
      <Card
        className={
          classes.cardBox + (extraClass && classes[extraClass] ? ' ' + classes[extraClass] : '')
        }
      >
        <CardContent>
          {Icon ? (
            <IconButton onClick={iconAction} className={classes.icon}>
              <Icon />
            </IconButton>
          ) : null}
          <Typography variant="subtitle2" className={classes.date}>
            {date}
          </Typography>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {text}
          </Typography>
        </CardContent>
        <CardActions className={classes.buttonBox}>
          {buttons.map((button) => (
            <Button onClick={button.action} key={button.key}>
              <Typography variant="body2" color={button.color}>
                {button.text}
              </Typography>
            </Button>
          ))}
        </CardActions>
      </Card>
    )
  }
)

import { createSlice } from '@reduxjs/toolkit'

const budgetRequestSlice = createSlice({
  name: 'budgetRequest',
  initialState: {
    data: {},
  },
  reducers: {
    setBudgetRequestData: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setBudgetRequestData } = budgetRequestSlice.actions

export const selectBudgetRequestData = (state) => state.budgetRequest.data

export const budgetRequestReducer = budgetRequestSlice.reducer

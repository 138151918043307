import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default (props) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="33"
      viewBox="0 0 51 33"
      {...props}
    >
      <path
        d="M28.003 19.927c-.526-.018-1.163.381-1.998.381-1.673 0-2.781-.762-3.29-2.343 1.163.018 2.38.018 2.944.018.691 0 .963-.219.963-.764 0-.4-.199-.672-.672-.672h-3.508c-.018-.128-.018-.274-.018-.418 0-.237.018-.509.054-.745h3.671c.491 0 .746-.255.746-.691 0-.418-.2-.708-.709-.708-.6 0-2.072 0-3.363.018.582-1.527 1.672-2.473 3.127-2.473.89 0 1.363.346 1.817.346.582 0 .818-.51.818-.963 0-.418-.163-.71-.636-.891-.545-.219-1.018-.327-1.945-.345-2.29-.018-4.471 1.6-5.253 4.325h-.727c-.527 0-.835.291-.835.745 0 .4.235.636.727.636h.563c-.037.273-.037.546-.037.819v.345h-.672c-.49 0-.8.199-.8.654s.273.727.8.727c.29 0 .49.018.836.018.581 2.744 2.617 4.253 5.107 4.271 1.764.018 3.054-.4 3.054-1.217 0-.436-.309-1.055-.764-1.073z"
        transform="translate(.5 .5)"
      />
      <path
        d="M48.864 18.255H37.902c.133-.748.206-1.517.206-2.302 0-.752-.067-1.49-.19-2.207h10.946c.46 0 .873-.277 1.05-.702.175-.424.078-.912-.247-1.238L38.532.672c-.324-.325-.814-.423-1.237-.246-.425.176-.702.59-.702 1.05v4.28h-3.368C30.974 3.938 28.112 2.845 25 2.845c-3.113 0-5.974 1.094-8.226 2.913H1.136C.509 5.756 0 6.266 0 6.893v5.717c0 .628.508 1.136 1.135 1.136h10.946c-.122.718-.19 1.454-.19 2.207 0 .785.074 1.553.207 2.302H1.136c-.459 0-.873.277-1.049.7-.176.425-.079.914.246 1.239l11.135 11.134c.217.218.508.333.803.333.146 0 .295-.029.434-.087.425-.176.701-.59.701-1.049v-4.281h3.49c2.233 1.761 5.047 2.816 8.105 2.816 3.058 0 5.872-1.055 8.104-2.817h15.76c.628 0 1.136-.508 1.136-1.135V19.39c0-.627-.509-1.136-1.136-1.136zM37.729 8.028c.627 0 1.136-.509 1.136-1.136V4.218l7.256 7.256h-8.802c-.455-1.248-1.095-2.406-1.886-3.445h2.296zM2.272 11.474V8.029h12.295c-.79 1.039-1.431 2.197-1.886 3.445H2.272zm9.998 12.497c-.627 0-1.136.509-1.136 1.136v2.675L3.88 20.526h8.838c.467 1.25 1.12 2.409 1.925 3.445h-2.371zM25 26.788c-5.975 0-10.836-4.861-10.836-10.835 0-5.975 4.861-10.836 10.836-10.836 5.974 0 10.836 4.861 10.836 10.835 0 5.975-4.861 10.836-10.836 10.836zm22.727-2.817H35.358c.804-1.037 1.458-2.195 1.925-3.445h10.445v3.445z"
        transform="translate(.5 .5)"
      />
    </SvgIcon>
  )
}

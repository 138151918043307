import { WIZARD_STEPS, APPLICATION_STATES, APPLICATION_FULL_PATHS } from '../../constants'

const { BUDGET, PAYMENT, BULLETIN, NETWORK, CONTRACT, PUTGAS } = WIZARD_STEPS
const { NOT_ASSIGNED, ASSIGNED, BUDGET_CREATED, BUDGET_SENDED } = APPLICATION_STATES
const { BUDGET_ACCEPTED, PAYMENT_VALIDATION, ORDER_PENDING, BUDGET_PAID } = APPLICATION_STATES
const { CERTIFICATE_ATTACHED } = APPLICATION_STATES
const { CERTIFICATE_REJECTED, CERTIFICATE_PROCESSED, FINISHED } = APPLICATION_STATES
const { REVIEW, REJECTED_SURVEY, REJECTED, REJECTED_2, CLIENT_CANCEL_PENDING } = APPLICATION_STATES

export const isProcessFinished = (model) => {
  return model['estado'] === FINISHED.KEY
}

export const getCurrentWizardStep = (model) => {
  const applicationState = model['estado']
  switch (applicationState) {
    case NOT_ASSIGNED.KEY:
    case ASSIGNED.KEY:
    case BUDGET_CREATED.KEY:
    case BUDGET_SENDED.KEY:
      return BUDGET.KEY
    case BUDGET_ACCEPTED.KEY:
      return PAYMENT.KEY
    case PAYMENT_VALIDATION.KEY:
    case ORDER_PENDING.KEY: {
      /* const distanceToConnection = model['presupuesto_exterior']['distancia_real']
      const nedgiaDistanceLimit = model['presupuesto_exterior']['distancia_asumida_nedgia']
      const paymentIsNeeded = distanceToConnection > nedgiaDistanceLimit
      if (paymentIsNeeded) return PAYMENT.KEY
      else */ return BULLETIN.KEY
    }
    case BUDGET_PAID.KEY:
    case CERTIFICATE_ATTACHED.KEY:
    case CERTIFICATE_REJECTED.KEY:
      return BULLETIN.KEY
    case CERTIFICATE_PROCESSED.KEY: {
      if (APPLICATION_FULL_PATHS[model['camino_solicitud']]) return NETWORK.KEY
      return CONTRACT.KEY
    }
    case FINISHED.KEY:
      return PUTGAS.KEY
    case REVIEW.KEY:
      if (model['estado_anterior'] === BUDGET_ACCEPTED) return PAYMENT.KEY
      return BUDGET.KEY
    case REJECTED_SURVEY.KEY:
    case REJECTED.KEY:
    case CLIENT_CANCEL_PENDING.KEY:
    case REJECTED_2.KEY:
      if (APPLICATION_FULL_PATHS[model['camino_solicitud']]) return BUDGET.KEY
      return BULLETIN.KEY
    default:
      return
  }
}

export const getActiveWizardSteps = (model) => {
  const applicationState = model['estado']
  switch (applicationState) {
    case NOT_ASSIGNED.KEY:
    case ASSIGNED.KEY:
    case BUDGET_CREATED.KEY:
    case BUDGET_SENDED.KEY:
      return [BUDGET.KEY]
    case BUDGET_ACCEPTED.KEY: {
      const distanceToConnection = model['presupuesto_exterior']['distancia_real']
      const nedgiaDistanceLimit = model['presupuesto_exterior']['distancia_asumida_nedgia']
      const paymentIsNeeded = distanceToConnection > nedgiaDistanceLimit
      if (paymentIsNeeded) return [PAYMENT.KEY]
      else return [BULLETIN.KEY]
    }
    case REVIEW.KEY:
      if (model['estado_anterior'] === BUDGET_ACCEPTED) return [BUDGET.KEY, PAYMENT.KEY]
      return [BUDGET.KEY]
    case PAYMENT_VALIDATION.KEY:
    case ORDER_PENDING.KEY:
      /*const distanceToConnection = model['presupuesto_exterior']['distancia_real']
      const nedgiaDistanceLimit = model['presupuesto_exterior']['distancia_asumida_nedgia']
      const paymentIsNeeded = distanceToConnection > nedgiaDistanceLimit
      if (paymentIsNeeded) return [PAYMENT.KEY, BULLETIN.KEY, NETWORK.KEY, CONTRACT.KEY, PUTGAS.KEY]
      else  */ return [
        BULLETIN.KEY,
        NETWORK.KEY,
        CONTRACT.KEY,
        PUTGAS.KEY,
      ]
    case BUDGET_PAID.KEY:
    case CERTIFICATE_ATTACHED.KEY:
    case CERTIFICATE_REJECTED.KEY:
      return [BULLETIN.KEY, NETWORK.KEY, CONTRACT.KEY, PUTGAS.KEY]
    case CERTIFICATE_PROCESSED.KEY:
      return [NETWORK.KEY, CONTRACT.KEY, PUTGAS.KEY]
    case FINISHED.KEY:
    case REJECTED_SURVEY.KEY:
    case REJECTED.KEY:
    case CLIENT_CANCEL_PENDING.KEY:
    case REJECTED_2.KEY:
      return []
    default:
      return
  }
}

export const gasApplicationsdStyles = (theme) => ({
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '60px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
    },
  },
  titleWrapper: {
    marginBottom: 40,
  },
  contentWrapper: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between' },
})

import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { verifyPwd } from '../../../../core/utils'
import { PasswordInput, ThemeButton } from '../../../../ui'
import { useAjaxContext } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'

export const ChangePwdFormComponent = ({ close }) => {
  const { formatMessage } = useIntl()
  const { put } = useAjaxContext()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)

  useEffect(() => {
    const { isValid, errorMssg: newError } = verifyPwd(
      formState['newPassword'],
      formState['repitNewPassword']
    )
    setIsValidPassword(isValid)
    setErrorMssg(newError)
  }, [formState['newPassword'], formState['repitNewPassword']]) // eslint-disable-line

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)
    if (isValidPassword) {
      const payload = {
        password: formState['currentPassword'],
        new_password: formState['newPassword'],
      }
      put('/usuario/modify_password', payload)
        .then(({ data }) => {
          feedback('success', 'Datos guardados')
          close()
        })
        .catch(({ response }) => console.log('error', response))
    } else {
      feedback('error', 'Contraseña inválida')
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmitForm}>
      {/* <Box mb={3}>
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage({ id: 'pages.pwdRecovery.title' })}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
          {formatMessage({ id: 'pages.pwdRecovery.extra' })}
        </Typography>
      </Box> */}
      <Box mb={1}>
        <PasswordInput
          value={formState['currentPassword']}
          name="currentPassword"
          // title={formatMessage({ id: 'pages.pwdRecovery.form.password.label' })}
          title="Contraseña actual"
          // placeholder={formatMessage({ id: 'pages.pwdRecovery.form.password.placeholder' })}
          placeholder="Contraseña actual"
          onChange={handleChangeForm}
          required
        />
        <PasswordInput
          value={formState['newPassword']}
          name="newPassword"
          // title={formatMessage({ id: 'pages.pwdRecovery.form.password.label' })}
          title="Nueva contraseña"
          // placeholder={formatMessage({ id: 'pages.pwdRecovery.form.password.placeholder' })}
          placeholder="Nueva contraseña"
          onChange={handleChangeForm}
          required
        />
        <PasswordInput
          value={formState['repitNewPassword']}
          name="repitNewPassword"
          title={formatMessage({ id: 'pages.pwdRecovery.form.repitPassword.label' })}
          placeholder={formatMessage({
            id: 'pages.pwdRecovery.form.repitPassword.placeholder',
          })}
          onChange={handleChangeForm}
          required
        />
      </Box>
      {errorMssg && (
        <Box mb={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" width={220}>
          <ThemeButton type="submit" fullWidth loading={loading}>
            Aceptar
          </ThemeButton>
          <ThemeButton color="default" fullWidth onClick={close}>
            Cancelar
          </ThemeButton>
        </Box>
      </Box>
      {/* <ThemeButton type="submit" loading={loading}>
        Cambiar contraseña
      </ThemeButton> */}
    </form>
  )
}

export const TYPES = {
  CENTERED: 'centered',
  FULL_SCREEN: 'fullScreen',
  RESPONSIVE: 'responsive',
}

export const DEFAULT_STATE = {
  Component: '',
  isOpen: false,
  type: TYPES.CENTERED,
  closable: false,
  maxWidth: 'sm',
}

export const WIZARD_STEPS = {
  BUDGET: {
    KEY: 0,
    LABEL_ID: 'pages.gasApplications.wizard.steps.0.label',
    TOOLTIP_ID: 'pages.gasApplications.wizard.steps.0.tooltip',
  },
  PAYMENT: {
    KEY: 1,
    LABEL_ID: 'pages.gasApplications.wizard.steps.1.label',
    TOOLTIP_ID: 'pages.gasApplications.wizard.steps.1.tooltip',
  },
  BULLETIN: {
    KEY: 2,
    LABEL_ID: 'pages.gasApplications.wizard.steps.2.label',

    TOOLTIP_ID: 'pages.gasApplications.wizard.steps.2.tooltip',
  },
  BULLETIN_EXTERIOR: {
    KEY: 2,
    LABEL_ID: 'pages.gasApplications.wizard.steps.2.exterior.label',
    TOOLTIP_ID: 'pages.gasApplications.wizard.steps.2.exterior.tooltip',
  },
  NETWORK: {
    KEY: 3,
    LABEL_ID: 'pages.gasApplications.wizard.steps.3.label',
    TOOLTIP_ID: 'pages.gasApplications.wizard.steps.3.tooltip',
  },
  CONTRACT: {
    KEY: 4,
    LABEL_ID: 'pages.gasApplications.wizard.steps.4.label',
    TOOLTIP_ID: 'pages.gasApplications.wizard.steps.4.tooltip',
  },
  PUTGAS: {
    KEY: 5,
    LABEL_ID: 'pages.gasApplications.wizard.steps.5.label',
    TOOLTIP_ID: 'pages.gasApplications.wizard.steps.5.tooltip',
  },
}

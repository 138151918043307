import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { withStyles, Container, Typography, Icon, Box, CircularProgress } from '@material-ui/core'

import { useAjaxContext } from '../../../core/ajax/contexts'
import { ThemeButton } from '../../../ui'
import { signupConfirmationStyles } from './signup-confirmation.styles'

export const SignupConfirmationPage = withStyles(signupConfirmationStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { signupToken } = useParams()
  const { signupConfirm } = useAjaxContext()

  const [isLoading, setIsLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    signupConfirm(signupToken)
      .then(() => {
        setSuccess(true)
        setIsLoading(false)
      })
      .catch(() => {
        setMessage('El registro ya ha sido confirmado o la petición ha caducado')
        setIsLoading(false)
      })
  }, []) // eslint-disable-line

  return isLoading ? (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress size={40} />
    </Box>
  ) : (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <div className={classes.panel}>
          <Icon color={success ? 'primary' : 'secondary'} className={classes.icon}>
            {success ? 'check_circle' : 'error'}
          </Icon>
          <div className={classes.content}>
            <Typography variant="h4" paragraph>
              {success ? formatMessage({ id: 'pages.signup.confirmation.success.title' }) : 'Error'}
            </Typography>
            {message && (
              <Typography variant="h6" paragraph>
                {/* {formatMessage({ id: 'pages.signup.confirmation.success.description' })} */}
                {message}
              </Typography>
            )}
          </div>
          {success && (
            <Link className={classes.button} to="/login" replace>
              <ThemeButton type="submit" color="secondary">
                {formatMessage({ id: 'pages.signup.confirmation.success.button.label' })}
              </ThemeButton>
            </Link>
          )}
        </div>
      </Container>
    </div>
  )
})

import React from 'react'
import { useIntl } from 'react-intl'
import { Grid, Box, Typography, Divider, ListItemText } from '@material-ui/core'

import { CLIENT_GRID_ROW_ITEMS } from '../../constants'
import { useBudgetConfirmationContext } from '../../contexts'
import { BudgetPanel } from '../budget-panel'

export const BudgetConfirmationView = () => {
  const { formatMessage, formatDate } = useIntl()
  const { application } = useBudgetConfirmationContext()
  const { usuario } = application

  return (
    <Box p={4} bgcolor="common.white" borderRadius={6}>
      <Box mb={4}>
        <Typography variant="body1" color="textSecondary" display="inline">
          {formatMessage({ id: 'pages.budget.view.title.1' })}
        </Typography>
        <Typography variant="subtitle1" display="inline">
          {` ${usuario.nombre || ''} ${usuario.apellidos || ''}`}
        </Typography>

        <Typography variant="body1" color="textSecondary" display="inline">
          {formatMessage({ id: 'pages.budget.view.title.2' })}
        </Typography>
      </Box>
      <Box mb={3}>
        <Grid container spacing={3}>
          {CLIENT_GRID_ROW_ITEMS.map((item, i) => (
            <Grid key={i} item {...(item.layout || { ...{ xs: 12, sm: 4 } })}>
              <ListItemText
                primary={formatMessage({ id: item.label })}
                secondary={item.valueParsed(application)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'body2' }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider light />
      <Box mt={3} mb={3}>
        <Grid container spacing={3} justify="flex-start">
          <Grid item>
            <Typography variant="subtitle2" display="inline" style={{ marginRight: 10 }}>
              {formatMessage({ id: 'pages.budget.view.detail.reference.label' })}:
            </Typography>
            <Typography variant="body2" display="inline" color="textSecondary">
              {application.solicitud_id}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" display="inline" style={{ marginRight: 10 }}>
              {formatMessage({ id: 'pages.budget.view.detail.date.label' })}:
            </Typography>
            <Typography variant="body2" display="inline" color="textSecondary">
              {formatDate(application.dg_ts_insert)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <BudgetPanel />
    </Box>
  )
}

import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, TableContainer, Table, TableHead, TableBody } from '@material-ui/core'
import { TableRow, TableCell, Typography, CircularProgress, Box } from '@material-ui/core'

import { basicListStyles } from './basic-list.styles'

export const BasicList = withStyles(basicListStyles)(
  ({ classes, config, data, loading = false }) => {
    const { formatDate, formatTime } = useIntl()
    const getParsedValue = (type, value) => {
      switch (type) {
        case 'date':
          return formatDate(new Date(value))
        case 'datetime':
          return formatDate(new Date(value)) + ' - ' + formatTime(new Date(value))
        default:
          return value
      }
    }

    return loading ? (
      <Box display="flex" alignItems="center" justifyContent="center" flexGrow={2}>
        <CircularProgress size={30} />
      </Box>
    ) : (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {config.map(({ align = 'left', label }, i) => (
                <TableCell key={i} align={align}>
                  <Typography variant="subtitle1">{label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                {config.map(({ key, align = 'left', type }, i) => (
                  <TableCell key={i} align={align}>
                    <Typography variant="body2" color="textSecondary">
                      {getParsedValue(type, row[key])}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
)

export const budgetRequestStyles = (theme) => ({
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    width: '100%',
    margin: '20px 0',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  },
})

const securePassword = (password) => {
  /* 
    Comprueba que la contraseña tenga:
    Una longitud mínima de 8
    1 número
    1 letra minúscula
    1 letra mayúscula
    1 carácter especial
    */
  const secureRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&'()*+,\ \-./:;<=>?@[\\\]^_`{|}~])(?=.*[a-zA-Z!#$%&'()*+,\ \-./:;<=>?@[\\\]^_`{|}~]).{8,}$/ // eslint-disable-line

  return secureRegex.test(password)
}

export const verifyPwd = (form, valid, setIsValid, setError) => {
  const pwd = form['password'],
    rePwd = form['repitPassword']
  /*
    Possible states (stateValue):
    S1- Empty pass -> not valid
    S2- Filled pass != repass -> Not valid
    S3- Filled pass == pass && not secure pass -> Not valid
    S4- Filled pass == pass && secure pass -> Valid
    */
  if (!pwd) {
    /* S1 */
    if (valid) {
      setIsValid(false)
    }
  } else if (pwd && pwd !== rePwd) {
    /* S2 */
    if (valid) {
      setIsValid(false)
    }
    setError('Las contraseñas no coinciden')
  } else if (pwd && pwd === rePwd && !securePassword(pwd)) {
    /* S3 */
    if (valid) {
      setIsValid(false)
    }
    setError('La contraseña no cumple los criterios de seguridad')
  } else if (pwd && pwd === rePwd && securePassword(pwd)) {
    /* S4 */
    if (!valid) {
      setIsValid(true)
    }
    setError('')
  }
}

import React from 'react'

import { BudgetConfirmationProvider } from '../../features/budget-confirmation'
import { BudgetConfirmationPage } from './components'

export const BudgetConfirmation = () => {
  return (
    <BudgetConfirmationProvider>
      <BudgetConfirmationPage />
    </BudgetConfirmationProvider>
  )
}

import { useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { downloadFile } from '../../../core/utils'
import { useModalContext, ConfirmationDialog } from '../../../ui'

export const useBudgetConfirmationActions = (state, setState) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { token } = useParams()
  const { getById, putById } = requests
  const {
    actions: { open },
  } = useModalContext()

  const showErrorMessage = (response) => {
    if (!response) {
      feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
      return 'No response'
    } else if (response.data?.message) {
      feedback('error', response.data.message)
    } else {
      feedback('error', formatMessage({ id: 'calls.default.error.description' }))
    }
    return response
  }

  const getApplicationByToken = useCallback(
    (token) =>
      new Promise((resolve, reject) => {
        setState({ ...state, loading: true, error: false })
        getById('/solicitudes/by_uuid', token)
          .then(({ data }) => {
            setState((prevState) => ({ ...prevState, application: data, loading: false }))
            resolve()
          })
          .catch(() => {
            setState((prevState) => ({ ...prevState, error: true, loading: false }))
            reject()
          })
      }),
    [] // eslint-disable-line
  )

  const downloadBudgetFile = useCallback(
    () =>
      new Promise((resolve, reject) => {
        getById('/documento/by_uuid', token)
          .then(({ data }) => downloadFile(data['documento'], data['extension'], data['nombre']))
          .then((response) => resolve(response))
          .catch(({ response }) => reject(showErrorMessage(response)))
      }),
    [] // eslint-disable-line
  )

  const fetchBudgetFile = useCallback(
    () =>
      new Promise((resolve, reject) => {
        getById('/documento/by_uuid', token)
          .then(({ data }) => resolve(data))
          .catch(({ response }) => reject(showErrorMessage(response)))
      }),
    [] // eslint-disable-line
  )

  const confirmBudgetCall = useCallback(
    () =>
      new Promise((resolve, reject) => {
        putById('/presupuesto/aceptar/by_uuid', token, { aceptacion: true })
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => reject(showErrorMessage(response)))
      }),
    [] // eslint-disable-line
  )

  const confirmBudget = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.budgetConfirmation.confirmDialog.title' }),
        text: formatMessage({ id: 'pages.budgetConfirmation.confirmDialog.description' }),
        yesText: formatMessage({ id: 'global.true' }),
        noText: formatMessage({ id: 'global.false' }),
        yesAction: () =>
          confirmBudgetCall().then(({ data }) => {
            if (!!data.usuario?.oauth2_id) {
              history.push('/login')
            } else {
              history.push('/signup')
            }
          }),
      },
      closable: false,
      type: 'centered',
    })
  }

  const cancelBudgetCall = useCallback(
    () =>
      new Promise((resolve, reject) => {
        putById('/solicitudes/anular_solicitud/by_uuid', token)
          .then((response) => {
            setState((prevState) => ({ ...prevState, cancelled: true }))
            resolve(response)
          })
          .catch(({ response }) => reject(showErrorMessage(response)))
      }),
    [] // eslint-disable-line
  )

  const cancelBudget = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.budgetConfirmation.cancelDialog.title' }),
        text: formatMessage({ id: 'pages.budgetConfirmation.cancelDialog.description' }),
        yesText: formatMessage({ id: 'global.true' }),
        noText: formatMessage({ id: 'global.false' }),
        yesAction: () => cancelBudgetCall(),
      },
      closable: false,
      type: 'centered',
    })
  }

  const changeChecked = (checked) => setState({ ...state, checked })

  useEffect(() => {
    getApplicationByToken(token)
  }, [token]) // eslint-disable-line

  return {
    getApplicationByToken,
    fetchBudgetFile,
    downloadBudgetFile,
    confirmBudget,
    cancelBudget,
    changeChecked,
  }
}

export const bulletinStepStyles = (theme) => ({
  downloadLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  urlLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    margin: '0 5px',
  },
  marginRight: {
    marginRight: 4,
  },
})

import React from 'react'
import { withStyles, FormControl, Select, MenuItem, ListItemText } from '@material-ui/core'
import { Checkbox, Typography } from '@material-ui/core'

import { selectInputStyles } from './select-input.styles'

export const SelectInput = React.memo(
  withStyles(selectInputStyles)(
    ({ classes, values = [], multiple = false, readOnly = false, ...props }) => {
      const value = props.value || []
      const typographyVariant = props.fontSize === 'small' ? '2' : '1'

      const selectValues = () => {
        return values.map((item, i) => (
          <MenuItem key={i} value={item.key}>
            {multiple ? (
              <>
                <Checkbox checked={value.indexOf(item.key) > -1} />
                <ListItemText primary={item.value} />
              </>
            ) : (
              item.value
            )}
          </MenuItem>
        ))
      }

      const multipleProps =
        multiple && values.length
          ? {
              renderValue: (selected) =>
                selected
                  .map((item) => {
                    let found = values.find((value) => value.key === item)
                    return found ? found.value : 'Unknown'
                  })
                  .join(', '),
            }
          : {}

      return (
        <FormControl
          variant="outlined"
          className={classes.root}
          required={props.required}
          disabled={props.disabled}
        >
          {props.title && (
            <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
              {props.title}
            </Typography>
          )}
          {readOnly ? (
            <Typography variant={`body${typographyVariant}`} color="textSecondary">
              {value && values ? values.find((option) => option.key === value)?.value : '--'}
            </Typography>
          ) : (
            <Select
              autoWidth
              className={classes.input}
              name={props.name}
              value={value}
              multiple={multiple}
              onChange={props.onChange}
              placeholder={props.placeholder}
              {...multipleProps}
            >
              {selectValues()}
            </Select>
          )}
        </FormControl>
      )
    }
  )
)

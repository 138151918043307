import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { withStyles, Typography, Divider } from '@material-ui/core'

import { ThemeButton, GridRow } from '../../../../ui'
import { ModalContext } from '../../../../ui/modal/contexts'
import { BudgetRequestPanel1Component } from '../budget-request-panel-1'
import { BudgetRequestPanel2Component } from '../budget-request-panel-2'
import { BudgetRequestCancelDialog } from '../budget-request-cancel-dialog'
import { URL_CANCEL_LINK, CLIENT_GRID_ROW_ITEMS } from '../../constants'
import { budgetRequestViewStyles } from './budget-request-view.styles'

export const BudgetRequestViewComponent = withStyles(budgetRequestViewStyles)(
  ({ classes, data }) => {
    const { formatMessage, formatDate } = useIntl()
    const history = useHistory()

    const [loading, setLoading] = useState(false) // eslint-disable-line

    const {
      actions: { open },
    } = useContext(ModalContext)

    const handleOpenModal = () =>
      open({
        Component: BudgetRequestCancelDialog,
        data: { handleCancel },
        type: 'centered',
      })

    const handleConfirm = () => {
      history.push('/signup')
    }

    const handleCancel = () => {
      // changeApplicationPublicState(appId, '00')
      //   .then(({ data }) => {
      //     setLoading(false)
      //     window.location = `${URL_CANCEL_LINK}?solicitud=${data['solicitud_id']}`
      //   })
      //   .catch(() => {
      //     setLoading(false)
      //   })
      window.location = `${URL_CANCEL_LINK}?solicitud=${data['solicitud_id']}`
    }

    const rawData = data['presupuesto_exterior']['fecha_solicitud']
    const procData = rawData ? formatDate(new Date(rawData)) : '-'

    return (
      <div className={classes.root}>
        <div className={classes.section1}>
          <Typography variant="body1" color="textSecondary" display="inline">
            {formatMessage({ id: 'pages.budget.view.title.1' })}
          </Typography>
          <span style={{ fontStyle: 'bold' }}>{` ${data['usuario']['nombre'] || ''} ${
            data['usuario']['apellidos'] || ''
          }`}</span>
          <Typography variant="body1" color="textSecondary" display="inline">
            {formatMessage({ id: 'pages.budget.view.title.2' })}
          </Typography>
        </div>
        <div className={classes.section1_1}>
          <GridRow items={CLIENT_GRID_ROW_ITEMS} data={data} />
        </div>
        <Divider light />
        <div className={classes.section2}>
          <span className={classes.section2_1}>
            <Typography variant="subtitle2" display="inline">
              {formatMessage({ id: 'pages.budget.view.detail.reference.label' })}:
            </Typography>
            <Typography
              variant="body2"
              display="inline"
              color="textSecondary"
              className={classes.textPadding}
            >
              {data['solicitud_id']}
            </Typography>
          </span>
          <span>
            <Typography variant="subtitle2" display="inline">
              {formatMessage({ id: 'pages.budget.view.detail.date.label' })}:
            </Typography>
            <Typography
              variant="body2"
              display="inline"
              color="textSecondary"
              className={classes.textPadding}
            >
              {procData}
            </Typography>
          </span>
        </div>
        <div className={classes.panel}>
          <BudgetRequestPanel1Component data={data} />
        </div>
        <div className={classes.panel}>
          <BudgetRequestPanel2Component data={data} />
        </div>
        <div className={classes.buttonsWrapper}>
          <ThemeButton labelM="large" variant="text" color="default" onClick={handleOpenModal}>
            {formatMessage({ id: 'pages.budget.view.button.cancel.label' })}
          </ThemeButton>
          <ThemeButton labelM="large" color="primary" loading={loading} onClick={handleConfirm}>
            {formatMessage({ id: 'pages.budget.view.button.accept.label' })}
          </ThemeButton>
        </div>
      </div>
    )
  }
)

import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Divider, Hidden, Checkbox } from '@material-ui/core'

import { ThemeButton, ExpansionPanel, GridRow } from '../../../../ui'
import { ReactComponent as ConnectionIcon } from '../../../../resources/images/icon_connection.svg'
import { feedback } from '../../../../core/feedback'
import { useBudgetConfirmationContext } from '../../contexts'
import { BudgetMandatoryMssgComponent } from './budget-mandatory-mssg.component'
import { BUDGET_GRID_ROW_ITEMS } from './constants'
import { budgetPanelStyles } from './budget-panel.styles'

export const BudgetPanel = withStyles(budgetPanelStyles)(({ classes }) => {
  const { formatMessage, formatNumber } = useIntl()
  const { downloadBudgetFile, confirmBudget, cancelBudget } = useBudgetConfirmationContext()
  const { checked, application: model } = useBudgetConfirmationContext()

  const freeL = model['presupuesto_exterior']['distancia_asumida_nedgia'] || '-'
  const priceL = model['presupuesto_exterior']['precio_metro_tuberia']
    ? formatNumber(model['presupuesto_exterior']['precio_metro_tuberia'], {
        style: 'currency',
        currency: 'EUR',
      })
    : '-'
  const totalPrice = formatNumber(
    model['presupuesto_exterior']['precio_instalacion_tuberia'] +
      model['presupuesto_exterior']['precio_instalacion_tuberia_iva'],
    {
      style: 'currency',
      currency: 'EUR',
    }
  )

  const handleDownloadFile = () => downloadBudgetFile()

  const handleCheck = () => {
    feedback('warning', formatMessage({ id: 'pages.budgetConfirmation.feedback.1' }))
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    if (!checked) {
      feedback('warning', formatMessage({ id: 'pages.budgetConfirmation.feedback.1' }))
    } else {
      confirmBudget()
    }
  }

  const handleCancel = () => cancelBudget()

  return (
    <form onSubmit={handleConfirm}>
      <Box p={2} bgcolor="grey.200" borderRadius={6}>
        <ExpansionPanel
          isExpanded={true}
          summary={
            <>
              <Box display="flex" mb={1}>
                <Box display="flex" alignItems="center" flexGrow={2}>
                  <Hidden xsDown className={classes.section_1_1_1}>
                    <ConnectionIcon />
                  </Hidden>
                  <Typography variant="h5">
                    {formatMessage({ id: 'pages.budgetConfirmation.panel.left.subtitle' })}
                  </Typography>
                </Box>
                <Box flexGrow={1} textAlign="right">
                  <Typography variant="body2" color="textSecondary">
                    {formatMessage({ id: 'pages.budgetConfirmation.panel.right.title' })}
                  </Typography>
                  <Typography variant="h5" color="secondary">
                    {totalPrice}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2">
                {formatMessage({ id: 'pages.budgetConfirmation.panel.left.title' })}
              </Typography>
            </>
          }
          details={
            <Box>
              <Typography variant="subtitle1">
                {formatMessage({ id: 'pages.budgetConfirmation.panel.detail.title' })}
              </Typography>
              <Box mt={1} mb={1}>
                <GridRow items={BUDGET_GRID_ROW_ITEMS} data={model} justify="space-between" />
              </Box>
              <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                {formatMessage(
                  { id: 'pages.budgetConfirmation.panel.description.included' },
                  { freeL, priceL }
                )}
              </Typography>
            </Box>
          }
          footer={
            <>
              <Divider light />
              <Box display="flex" alignItems="center" mt={1} mb={1}>
                <Checkbox
                  color="primary"
                  size="small"
                  checked={checked}
                  onClick={handleCheck}
                  required
                />
                <BudgetMandatoryMssgComponent classes={classes} />
              </Box>
              <Divider light />
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Box flex={1}>
                  <Typography variant="subtitle2">
                    {formatMessage({ id: 'pages.budgetConfirmation.panel.downloadMssg' })}
                  </Typography>
                  <span onClick={handleDownloadFile}>
                    <Typography variant="body2" className={classes.downloadLink}>
                      {formatMessage({
                        id: 'pages.budgetConfirmation.panel.downloadLink.label',
                      })}
                    </Typography>
                  </span>
                </Box>
              </Box>
            </>
          }
        />
      </Box>
      <Box marginTop={2} display="flex" flexWrap="wrap-reverse" justifyContent="space-around">
        <ThemeButton labelM="large" variant="text" color="default" onClick={handleCancel}>
          {formatMessage({ id: 'pages.budgetConfirmation.view.button.cancel.label' })}
        </ThemeButton>
        <ThemeButton labelM="large" color="primary" type="submit">
          {formatMessage({ id: 'pages.budgetConfirmation.view.button.accept.label' })}
        </ThemeButton>
      </Box>
    </form>
  )
})

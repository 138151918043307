export const FIRST_APPLICATION_PATH = {
  KEY: '01',
  DESCRIPTION:
    'The application is created by client or installer and the market is SH/SP/PYMES or doesnt have network',
}

export const SECOND_APPLICATION_PATH = {
  KEY: '02',
  DESCRIPTION: 'The application is created by client or installer and the market is SV',
}

export const THIRD_APPLICATION_PATH = {
  KEY: '03',
  DESCRIPTION: 'The application is created by client or installer and the market is ES',
}

export const APPLICATION_FULL_PATHS = {
  [FIRST_APPLICATION_PATH.KEY]: [FIRST_APPLICATION_PATH.DESCRIPTION],
}

export const APPLICATION_HALF_PATHS = {
  [SECOND_APPLICATION_PATH.KEY]: [SECOND_APPLICATION_PATH.DESCRIPTION],
  [THIRD_APPLICATION_PATH.KEY]: [THIRD_APPLICATION_PATH.DESCRIPTION],
}

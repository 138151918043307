import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Divider } from '@material-ui/core'

import { CLIENT_JOURNEY_LINK } from '../constants'

export const HomeDashboardComponent = () => {
  const { formatMessage } = useIntl()

  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="column"
        width={200}
        p={3}
        borderRadius={4}
        bgcolor="common.white"
        boxShadow="0 4px 9px 0 rgba(0, 0, 0, 0.02)"
      >
        <Box mb={1} flex={1}>
          <Typography variant="body2" color="textSecondary">
            {formatMessage({ id: 'pages.dashboard.panels.1.title' })}
          </Typography>
          <Typography variant="h6" style={{ margin: '0.5em 0' }}>
            {formatMessage({ id: 'pages.dashboard.panels.1.subtitle' })}
          </Typography>
        </Box>
        <Divider light />
        <Box mt={2}>
          <Typography variant="subtitle2">
            {formatMessage({ id: 'pages.dashboard.panels.1.description' })}
          </Typography>
          <a target="_blank" rel="noopener noreferrer" href={CLIENT_JOURNEY_LINK}>
            <Typography variant="body2" color="primary">
              {formatMessage({ id: 'pages.dashboard.panels.1.link' })}
            </Typography>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

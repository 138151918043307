export const notificationCardStyles = (theme) => ({
  cardBox: {
    background: '#f3f3f3',
    margin: '0.5em 0',
    borderRadius: '5px',
    transition: 'all 1s ease-out',
  },
  date: { marginBottom: '1em', fontSize: '80%' },
  title: { margin: '0', fontSize: '90%' },
  text: { margin: '0', fontSize: '80%' },
  icon: { float: 'right', color: 'black' },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0',
    paddingBottom: '0',
  },
  pending: {
    boxShadow: '5px 5px #999',
    transform: 'translateY(-5px)',
  },
})

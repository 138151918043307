import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Button } from '@material-ui/core'
import { Document, Page, pdfjs } from 'react-pdf'
import { viewPdfDialogStyles } from './view-pdf-dialog.styles'

export const ViewPDFDialog = withStyles(viewPdfDialogStyles)(
  ({ classes, close, file, setDisabled, shortcutEnabled = false }) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    const { formatMessage } = useIntl()
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages)
    }

    const handleNextPage = () => {
      setIsLoading(true)
      if (pageNumber < numPages) setPageNumber(pageNumber + 1)
      else {
        setDisabled(false)
        close()
      }
    }

    const handlePrevPage = () => {
      setIsLoading(true)
      if (pageNumber > 1) setPageNumber(pageNumber - 1)
      else {
        close()
      }
    }

    const handleLastPage = () => {
      setIsLoading(true)
      setPageNumber(numPages)
    }

    return (
      <>
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
          className={classes.pdfWrapper}
        >
          <Page pageNumber={pageNumber} scale={1.2} onLoadSuccess={() => setIsLoading(false)} />
        </Document>
        {!isLoading ? (
          <div className={classes.pdfButtonsWrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.pdfButton}
              onClick={handlePrevPage}
            >
              {pageNumber === 1
                ? formatMessage({ id: 'global.close' })
                : formatMessage({ id: 'global.previous' })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.pdfButton}
              onClick={handleNextPage}
            >
              {pageNumber === numPages
                ? formatMessage({ id: 'global.accept' })
                : formatMessage({ id: 'global.next' })}
            </Button>
            {shortcutEnabled && pageNumber !== numPages ? (
              <Button
                variant="contained"
                color="secondary"
                className={classes.pdfButton}
                onClick={handleLastPage}
              >
                {formatMessage({ id: 'global.goLastPage' })}
              </Button>
            ) : null}
          </div>
        ) : null}
      </>
    )
  }
)

import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Container, Typography, Box, Divider } from '@material-ui/core'

import { NEDGIA_CONTACT } from '../constants'
import { contactStyles } from './contact.styles'

export const ContactPage = withStyles(contactStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <Box height="100%">
      <Container className={classes.container} maxWidth="md">
        <Box mb={4}>
          <Typography variant="h4" color="primary" paragraph>
            {formatMessage({ id: 'pages.contact.title' })}
          </Typography>
        </Box>
        <Box bgcolor="common.white" borderRadius={4} p={4}>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
              {formatMessage({ id: 'pages.contact.panel.title1' })}
            </Typography>
            <Typography variant="body2" className={classes.sectionText} color="textSecondary">
              {formatMessage({ id: 'pages.contact.panel.description1' })}
            </Typography>
            <Typography variant="body2" className={classes.sectionText} color="textSecondary">
              <a
                className={classes.corporativeLink}
                target="_blank"
                rel="noopener noreferrer"
                href={NEDGIA_CONTACT.WEB}
              >
                {NEDGIA_CONTACT.WEB}
              </a>
            </Typography>
            {NEDGIA_CONTACT.PHONE_1 ? (
              <Typography variant="body2" className={classes.sectionText} color="textSecondary">
                {formatMessage({ id: 'pages.contact.panel.telf' })}
                <a className={classes.corporativeLink} href={'tel:+34 ' + NEDGIA_CONTACT.PHONE_1}>
                  {NEDGIA_CONTACT.PHONE_1}
                </a>
              </Typography>
            ) : null}
            {NEDGIA_CONTACT.PHONE_2 ? (
              <Typography variant="body2" className={classes.sectionText} color="textSecondary">
                {formatMessage({ id: 'pages.contact.panel.telf' })}
                <a className={classes.corporativeLink} href={'tel:+34 ' + NEDGIA_CONTACT.PHONE_2}>
                  {NEDGIA_CONTACT.PHONE_2}
                </a>
              </Typography>
            ) : null}
          </Box>
          <Divider light />
          <Box mt={2}>
            <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
              {formatMessage({ id: 'pages.contact.panel.title2' })}
            </Typography>
            <Typography variant="body2" className={classes.sectionText} color="textSecondary">
              {'Facebook: '}
              <a
                className={classes.corporativeLink}
                href={'https://www.' + NEDGIA_CONTACT.FACEBOOK}
              >
                {NEDGIA_CONTACT.FACEBOOK}
              </a>
            </Typography>
            <Typography variant="body2" className={classes.sectionText} color="textSecondary">
              {'Twitter: '}
              <a
                className={classes.corporativeLink}
                href={'https://www.twitter.com/' + NEDGIA_CONTACT.TWITTER}
              >
                {NEDGIA_CONTACT.TWITTER}
              </a>
            </Typography>
            <Typography variant="body2" className={classes.sectionText} color="textSecondary">
              {'Instagram: '}
              <a
                className={classes.corporativeLink}
                href={'https://www.instagram.com/' + NEDGIA_CONTACT.INSTAGRAM}
              >
                @{NEDGIA_CONTACT.INSTAGRAM}
              </a>
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
})

import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { withStyles, Checkbox, Box, Typography, Link } from '@material-ui/core'

import { selectBudgetRequestData } from '../../budget-request'
import { AjaxContext } from '../../../core/ajax'
import { TextInput, PasswordInput, ThemeButton } from '../../../ui'
import { signupFormStyles } from './signup-form.styles'
import { feedback } from '../../../core/feedback/components/feedback'
import { getTypeOfDocument, isValidEmail, verifyPwd } from '../../../core/utils'
import { useDocumentDialogs } from '../hooks'
import { DATA_POLICY_URL } from '../constants'

export const SignupFormComponent = withStyles(signupFormStyles)(({ classes }) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const budgetData = useSelector(selectBudgetRequestData)
  const { signup, get } = useContext(AjaxContext)
  const { warnPassport } = useDocumentDialogs()

  const query = new URLSearchParams(useLocation().search)

  const initialFormState = {
    nombre: budgetData['nombre'] || '',
    apellidos: budgetData['apellidos'] || '',
    telefono: budgetData['telefono1'] || '',
    email: query.get('email') || (budgetData['usuario'] && budgetData['usuario']['email']) || '',
    identificador:
      query.get('nif') || (budgetData['usuario'] && budgetData['usuario']['identificador']) || '',
    acepta_politica_privacidad: true,
    acepta_comunicaciones_comerciales: false,
  }
  // const initialFormState = {
  //   nombre: budgetData['nombre'] || '',
  //   telefono: budgetData['telefono1'] || '',
  //   email: 'daniel.ran@basetis.com',
  //   identificador: '21971160L',
  //   password: 'aaaaaa1A!',
  //   repitPassword: 'aaaaaa1A!',
  // }

  const [formState, setFormState] = useState(initialFormState)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const completeSubmitForm = async () => {
    try {
      setLoading(true)
      const userValidity = await get(
        `/usuario/usuario_valido/${formState['email']}/${formState['identificador']}`
      )
      if (!userValidity?.data?.validez_usuario) {
        setLoading(false)
        return feedback('error', userValidity.data.error || 'El usuario no es válido')
      }
      const signupUser = await signup(formState)
      if (signupUser) {
        feedback(
          'success',
          'Petición de registro realizada. Recibirás un correo para formalizar el registro.'
        )
        setLoading(false)
        history.push('/login')
      }
    } catch {
      feedback(
        'error',
        'No ha sido posible realizar el registro. Por favor, contacte con el administrador.'
      )
      setLoading(false)
    }
  }

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    const docType = getTypeOfDocument(formState['identificador'])
    const pwdValidation = verifyPwd(formState['password'], formState['repitPassword'])
    if (!isValidEmail(formState['email'])) {
      return setErrorMssg('Introduce una dirección de email válida')
    } else if (!pwdValidation.isValid) {
      return setErrorMssg(pwdValidation.errorMssg)
    } else if (!docType) {
      formState['tipo_identificador'] = 'Passport'
      return warnPassport(completeSubmitForm)
    } else {
      formState['tipo_identificador'] = docType
    }
    if (formState['nombre'].length === 0 || formState['apellidos'].length === 0) {
      return setErrorMssg('Introduce un nombre y apellidos válidos')
    }

    await completeSubmitForm()
  }

  // const handleCheckTerminosPolitica = () => {
  //   setFormState({
  //     ...formState,
  //     acepta_politica_privacidad: !formState.acepta_politica_privacidad,
  //   })
  // }

  const handleCheckComunicacionesComerciales = () => {
    setFormState({
      ...formState,
      acepta_comunicaciones_comerciales: !formState.acepta_comunicaciones_comerciales,
    })
  }

  const handleOpenPrivacyModal = () => window.open(DATA_POLICY_URL)
  return (
    <form onSubmit={handleSubmitForm}>
      <TextInput
        required
        value={formState['nombre']}
        name="nombre"
        title={formatMessage({ id: 'pages.signup.form.nombre.title' })}
        placeholder={formatMessage({ id: 'pages.signup.form.nombre.placeholder' })}
        onChange={handleChangeForm}
      />
      <TextInput
        required
        value={formState['apellidos']}
        name="apellidos"
        title={formatMessage({ id: 'pages.signup.form.apellidos.title' })}
        placeholder={formatMessage({ id: 'pages.signup.form.apellidos.placeholder' })}
        onChange={handleChangeForm}
      />
      <TextInput
        type="email"
        required
        value={formState['email']}
        name="email"
        title={formatMessage({ id: 'pages.signup.form.email.title' })}
        placeholder={formatMessage({ id: 'pages.signup.form.email.placeholder' })}
        onChange={handleChangeForm}
      />
      <TextInput
        value={formState['identificador']}
        name="identificador"
        title={formatMessage({ id: 'pages.signup.form.identifier.title' })}
        placeholder={formatMessage({ id: 'pages.signup.form.identifier.placeholder' })}
        onChange={handleChangeForm}
        required
        disabled={!!query.get('email') || Object.keys(budgetData).length !== 0}
      />
      <PasswordInput
        value={formState['password']}
        name="password"
        title={formatMessage({ id: 'pages.signup.form.password.title' })}
        placeholder={formatMessage({ id: 'pages.signup.form.password.placeholder' })}
        onChange={handleChangeForm}
        required
        autoComplete="new-password"
      />
      <Box mt={1} bgcolor="grey">
        <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
          {formatMessage({ id: 'pages.pwdRecovery.extra' })}
        </Typography>
      </Box>
      <PasswordInput
        value={formState['repitPassword']}
        name="repitPassword"
        title={formatMessage({ id: 'pages.signup.form.repitPassword.title' })}
        placeholder={formatMessage({ id: 'pages.signup.form.repitPassword.placeholder' })}
        onChange={handleChangeForm}
        required
      />
      {/* <Box display={'flex'} alignItems="center" className={classes.checkboxWrapper}>
        <Box>
          <Checkbox color="primary" size="small" required onClick={handleCheckTerminosPolitica} />
        </Box>
        <SignupMandatoryMssgComponent classes={classes} />
      </Box> */}
      <Box>
        <Box mt={1}>
          <Typography color="primary" style={{ fontWeight: 'bold' }}>
            {formatMessage({ id: 'pages.signup.terms.title' })}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography color="primary">
            {formatMessage({ id: 'pages.signup.terms.text.1' })}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography color="primary">
            {formatMessage({ id: 'pages.signup.privacy.policy.header' })}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'flex-start'}>
          <Checkbox color="primary" size="small" onClick={handleCheckComunicacionesComerciales} />
          <Box mt={1}>
            <Typography color="primary">
              {formatMessage({ id: 'pages.signup.comercial.comunications.text.1' })}
            </Typography>
          </Box>
        </Box>
        <Box mt={1}>
          <Typography display="inline" color="primary">
            {formatMessage({ id: 'pages.signup.privacy.policy.text.1' })}
          </Typography>
          <Link onClick={handleOpenPrivacyModal}>
            <Typography
              display="inline"
              style={{ cursor: 'pointer', fontWeight: 'bold' }}
              color="textSecondary"
            >
              {formatMessage({ id: 'pages.signup.form.checkbox.message4' })}
            </Typography>
          </Link>
          <Typography display="inline" color="primary">
            {formatMessage({ id: 'pages.signup.privacy.policy.text.2' })}
          </Typography>
        </Box>
      </Box>
      {errorMssg && (
        <Box mt={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <ThemeButton type="submit" fullWidth loading={loading}>
        {formatMessage({ id: 'pages.signup.form.button.label' })}
      </ThemeButton>
    </form>
  )
})

import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { withStyles, Container, Typography, Hidden } from '@material-ui/core'

import { LoginFormComponent } from '../../../features/login'
import { loginStyles } from './login.styles'

export const LoginPage = withStyles(loginStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.root}>
      <div className={classes.leftWrapper}>
        <Container className={classes.container} maxWidth="sm">
          <div className={classes.panel}>
            <Typography variant="h4" color="primary" gutterBottom>
              {formatMessage({ id: 'pages.login.title' })}
            </Typography>
            <LoginFormComponent />
            <div className={classes.signupWrapper}>
              <Typography variant="body2" display="inline" color="textSecondary">
                {formatMessage({ id: 'pages.login.signup.message1' })}
              </Typography>
              <Link to={{ pathname: '/signup' }} className={classes.signupLink}>
                <Typography variant="subtitle2" display="inline" className={classes.signupMessage}>
                  {formatMessage({ id: 'pages.login.signup.message2' })}
                </Typography>
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <Hidden smDown>
        <div className={classes.rightWrapper} />
      </Hidden>
    </div>
  )
})

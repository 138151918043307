export const MATERIAL_COMPONENTS_OVERRIDES = {
  MuiInputBase: {
    root: {
      height: 48,
      backgroundColor: '#0000000A', // opacity 4% = 0A
    },
    input: {
      height: '-webkit-fill-available',
      fontSize: 16,
      fontFamily: 'FSEmeric-Regular',
    },
  },
  MuiOutlinedInput: {
    input: {
      '&:-webkit-autofill': {
        height: '10px',
      },
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
    },
  },
  MuiAccordionSummary: {
    content: {
      display: 'block',
      '&$expanded': {
        margin: '12px 0',
      },
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: 'none',
    },
  },
}

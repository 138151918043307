export const CHANGE_INSTALLER_OBSERVATIONS = [
  { key: '', value: '' },
  { key: 'No me ha contactado.', value: 'No me ha contactado.' },
  {
    key: 'Mala experiencia con el instalador elegido',
    value: 'Mala experiencia con el instalador elegido',
  },
  {
    key: 'No me ha enviado el presupuesto de la instalación interior.',
    value: 'No me ha enviado el presupuesto de la instalación interior.',
  },
  {
    key: 'No me gusta su propuesta de aparatos y solución de instalación.',
    value: 'No me gusta su propuesta de aparatos y solución de instalación.',
  },
  { key: 'Es caro.', value: 'Es caro.' },
  { key: 'Quiero más opciones de presupuesto.', value: 'Quiero más opciones de presupuesto.' },
  { key: 'Sin especificar.', value: 'Sin especificar.' },
]

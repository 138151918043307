const styledBy = (property, mapping) => (props) => mapping[props[property]]

export const themeButtonStyles = (theme) => ({
  root: {
    margin: '20px 0',
    borderRadius: 6,
    height: 48,
    backgroundColor: styledBy('version', {
      delete: '#dd422a',
    }),
  },
  label: {
    margin: styledBy('labelM', {
      large: '4px 50px',
      medium: '4px 20px',
      small: 'auto',
      undefined: 'auto',
    }),
    textTransform: 'none',
    fontFamily: 'FSEmeric-Medium',
    fontSize: 14,
  },
})

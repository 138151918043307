import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Container, Typography, Box, Divider, Switch } from '@material-ui/core'

import { settingsStyles } from './settings.styles'

export const SettingsPage = withStyles(settingsStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  const FakeSwitch = () => (
    <Switch
      classes={{
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  )

  return (
    <Box height="100%">
      <Container className={classes.container} maxWidth="md">
        <Box mb={4}>
          <Typography variant="h4" color="primary" paragraph>
            {formatMessage({ id: 'pages.settings.title' })}
          </Typography>
        </Box>
        <Box bgcolor="common.white" borderRadius={4} p={4}>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              {formatMessage({ id: 'pages.settings.panel.title1' })}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <Typography variant="body2" color="textSecondary">
                  {formatMessage({ id: 'pages.settings.panel.description1' })}
                </Typography>
              </Box>
              <FakeSwitch />
            </Box>
          </Box>
          <Divider light />
          <Box mt={2}>
            <Typography variant="h5" gutterBottom>
              {formatMessage({ id: 'pages.settings.panel.title2' })}
            </Typography>
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <Typography variant="body2" color="textSecondary">
                  SMS
                </Typography>
              </Box>
              <FakeSwitch />
            </Box>
            <Box display="flex" alignItems="center">
              <Box flex={1}>
                <Typography variant="body2" color="textSecondary">
                  Mail
                </Typography>
              </Box>
              <FakeSwitch />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
})

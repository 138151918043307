import { useEffect, useState } from 'react'

//Hook for sizing, based on https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element
export const useContainerSize = (myRef) => {
  const getDimensions = () => {
    return myRef.current.parentNode.getBoundingClientRect()
  }
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  })
  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions())
    }
    if (myRef.current) {
      setDimensions(getDimensions())
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [myRef]) // eslint-disable-line
  return dimensions
}

export default useContainerSize

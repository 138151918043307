export const expansionPanelStyles = (theme) => ({
  accordion: {
    backgroundColor: 'transparent',
  },
  accordionToggler: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  accordionSummary: {
    userSelect: 'auto',
  },
})

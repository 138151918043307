import React from 'react'
import { useIntl } from 'react-intl'

import { useModalContext, ThemeButton } from '../../../../../ui'
import { RequestChangesDialog } from '../../dialogs'
import { useGasAppData } from '../../../hooks'

export const RequestChangesBtn = ({ model }) => {
  const { formatMessage } = useIntl()
  const { requestReview } = useGasAppData()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenModal = () =>
    open({
      Component: RequestChangesDialog,
      data: { id: model.id, requestReview },
      type: 'responsive',
    })

  return (
    <ThemeButton labelM="large" color="primary" onClick={handleOpenModal}>
      {formatMessage({ id: 'pages.gasApplications.wizard.budget.requestChangesBtn.title' })}
    </ThemeButton>
  )
}

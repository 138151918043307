import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Tooltip, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { useModalContext } from '../../../../../ui'
import { CancelAppDialog } from '../../dialogs'
import { useGasAppData } from '../../../hooks'
import { cancelBtnStyles } from './cancel-btn.styles'

export const CancelBtn = withStyles(cancelBtnStyles)(({ classes, model }) => {
  const { formatMessage } = useIntl()
  const { updateGasApps, cancelApplication } = useGasAppData()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenModal = () =>
    open({
      Component: CancelAppDialog,
      data: { application: model, cancelApplication, updateGasApps },
      type: 'centered',
    })

  return (
    <Tooltip
      title={formatMessage({
        id: 'pages.gasApplications.wizard.budget.cancelBtn.title',
      })}
      arrow
      placement="top"
    >
      <Button variant="contained" disableElevation classes={classes} onClick={handleOpenModal}>
        <CloseIcon />
      </Button>
    </Tooltip>
  )
})

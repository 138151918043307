import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withStyles, Container } from '@material-ui/core'

import { selectUser, setSuppliesData } from '../../../features/profile/slices'
import { selectSupplies } from '../../../features/profile/slices'
import { getSuppliesEffect } from '../effects'
import { ProfileUser, ProfileSupplies } from '../../../features/profile'
import { profileStyles } from './profile.styles'
import { useAjaxContext } from '../../../core/ajax'

export const ProfilePage = withStyles(profileStyles)(({ classes }) => {
  const user = useSelector(selectUser)
  const supplies = useSelector(selectSupplies)
  const dispatch = useDispatch()
  const { get } = useAjaxContext()
  const userId = useSelector((state) => state.global.authData.user_id)

  // useEffect(getUserEffect(setUserData, user, userState.user_id, get, dispatch), [])
  useEffect(getSuppliesEffect(setSuppliesData, supplies, userId, get, dispatch), [])

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        {Object.keys(user).length ? <ProfileUser forceOpenEdit={!user['nombre']} /> : null}
        {supplies.length !== 0 ? <ProfileSupplies /> : null}
      </Container>
    </div>
  )
})

import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

const ERR_CODE_NOT_CONFIRMED = 3503

export const useLoginCalls = () => {
  const { login, postPublic } = useAjaxContext()
  const { formatMessage } = useIntl()
  // const dispatch = useDispatch()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () => {}

  const loginCall = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        login(payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data.code === ERR_CODE_NOT_CONFIRMED) {
                  return reject('inactive_user')
                }
                break
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const resendValidation = useCallback(
    (mail) =>
      new Promise((resolve, reject) => {
        postPublic(`/usuario/resend_mail/${mail}`, { area: 'cliente' })
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line
  )

  return {
    loginCall,
    resendValidation,
  }
}

const { REACT_APP_WP_URL } = process.env

export const DASHBOARD_ITEMS = [
  // TODO: Replace literals for ids inside i18n and add formatMessage to father
  {
    title: 'Servicio de Nedgia',
    subtitle: 'Lectura de contador',
    description: 'Gestiona la lectura del contador',
    linkLabel: 'Pulsa aquí',
    linkTo: '/',
  },
  {
    title: 'Servicio de Nedgia',
    subtitle: '¿Quieres gas en tu domicilio?',
    description: 'Inicia el viaje de cliente',
    linkLabel: 'Pulsa aquí',
    linkTo: '/',
  },
]

export const CLIENT_JOURNEY_LINK = REACT_APP_WP_URL

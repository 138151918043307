import { APPLICATION_STATES } from '../../constants'

export const CAN_SELECT_INSTALLER = {
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.VALIDATION_PENDING.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
}

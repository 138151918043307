export const profileUserStyles = (theme) => ({
  userImg: {
    width: 45,
    height: 45,
    marginRight: 10,
  },
  primaryButtonIcon: {
    '& g g': { stroke: theme.palette.primary.contrastText },
  },
})

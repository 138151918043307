import React from 'react'
import { useIntl } from 'react-intl'
import { Button } from '@material-ui/core'

import { useModalContext } from '../../../../../ui'
import { ReviewDialog } from '../../dialogs'
import { useGasAppCalls } from '../../../hooks'

export const ReviewBtn = ({ model }) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { getReview } = useGasAppCalls()

  const handleOpenModal = () =>
    open({
      Component: ReviewDialog,
      data: { reviewId: model.valoracion_id, getReview },
      type: 'responsive',
      closable: true,
    })

  return (
    <Button color="secondary" variant="contained" onClick={handleOpenModal} size="small">
      {formatMessage({ id: 'pages.gasApplications.panel.review.btn.label' })}
    </Button>
  )
}

import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Hidden } from '@material-ui/core'

import { ExpansionPanel } from '../../../../../ui'
import { ReactComponent as PutgasIcon } from '../../../../../resources/images/icon_putgas.svg'
import { putgasStepStyles } from './putgas-step.style'

export const PutgasStepComponent = withStyles(putgasStepStyles)(({ classes, model }) => {
  const { formatMessage, formatDate } = useIntl()

  return (
    <ExpansionPanel
      isExpanded
      triggerPosition="right"
      summary={
        <Box>
          <Box mb={1} display="flex" alignItems="center" flexGrow={2}>
            <Hidden xsDown>
              <PutgasIcon />
            </Hidden>
            <Typography variant="h5">
              {formatMessage({ id: 'pages.gasApplications.wizard.putgas.title' })}
            </Typography>
          </Box>
          <Typography variant="body2" paragraph>
            {formatMessage({ id: 'pages.gasApplications.wizard.steps.5.tooltip' })}
          </Typography>
          <Box ml={1} display="flex" alignItems="center" flexWrap="wrap">
            <Box display="flex" className={classes.marginRightContainer}>
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({ id: 'pages.gasApplications.wizard.putgas.concertdate' })}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {model['fecha_puesta_servicio']
                  ? formatDate(new Date(model['fecha_puesta_servicio']))
                  : '--'}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body2" className={classes.marginRight}>
                {formatMessage({ id: 'pages.gasApplications.wizard.putgas.putgasdate' })}:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {model['fecha_puesta_servicio']
                  ? formatDate(new Date(model['fecha_puesta_servicio']))
                  : '--'}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      // details={<PutgasEventsList model={model} />}
    />
  )
})

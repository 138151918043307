import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { withStyles, Container, Typography } from '@material-ui/core'

import { AjaxContext } from '../../../../core/ajax'
import { TextInput, ThemeButton } from '../../../../ui'
import feedback from '../../../../core/feedback/components/feedback'
import { setBudgetRequestData } from '../../slices'
import { BudgetRequestAuthStyles } from './budget-request-auth.styles'

export const BudgetRequestAuthComponent = withStyles(BudgetRequestAuthStyles)(({ classes }) => {
  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  let { appId } = useParams()
  const { fetchBudget } = useContext(AjaxContext)
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setLoading(true)
    const upperIdentifier = formState['identifier'].toUpperCase()
    // const docType = getTypeOfDocument(upperIdentifier)
    // if (!docType) {
    //   setLoading(false)
    //   return feedback(
    //     'error',
    //     formatMessage({ id: 'pages.budget.view.error.wrongFormat.description' })
    //   )
    // }
    fetchBudget(appId, upperIdentifier)
      .then(({ data }) => {
        setLoading(false)
        if (data['estado'] === '03') {
          return feedback(
            'error',
            formatMessage({ id: 'pages.budget.view.error.cancelled.description' })
          )
        } else if (!data['presupuesto_exterior']) {
          return feedback(
            'error',
            formatMessage({ id: 'pages.budget.view.error.noBudget.description' })
          )
        } else {
          dispatch(setBudgetRequestData(data))
        }
      })
      .catch((error) => {
        setLoading(false)
        // if(!docType){
        //   return feedback(
        //     'error',
        //     formatMessage({id:'pages.budget.view.error.wrongFormat.description'})
        //   )
        // }
        //
        if (!error.response || !error.response.status) {
          return feedback(
            'error',
            formatMessage({ id: 'pages.budget.view.error.failed.description' })
          )
        }
        if (
          error.response.status === 404 || //Unknown id
          error.response.status === 401 //Wrong NIF
        ) {
          return feedback(
            'error',
            formatMessage({ id: 'pages.budget.view.error.wrongNif.description' })
          )
        }
      })
  }

  return (
    <div className={classes.root}>
      <div className={classes.description}>
        <Typography variant="h4" color="primary" paragraph>
          {formatMessage({ id: 'pages.budget.auth.title.label' })}
        </Typography>
        <Typography variant="body1" paragraph>
          {formatMessage({ id: 'pages.budget.auth.description.label' })}
        </Typography>
      </div>
      <Container maxWidth="xs" className={classes.form}>
        <form onSubmit={handleSubmitForm}>
          <TextInput
            required
            value={formState['identifier']}
            name="identifier"
            placeholder={formatMessage({
              id: 'pages.budget.auth.form.field.identifier.placeholder',
            })}
            onChange={handleChangeForm}
          />
          <div className={classes.button}>
            <ThemeButton labelM="large" type="submit" color="secondary" loading={loading}>
              {formatMessage({ id: 'pages.budget.auth.button.label' })}
            </ThemeButton>
          </div>
        </form>
      </Container>
    </div>
  )
})

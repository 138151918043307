import React from 'react'
import { useIntl } from 'react-intl'

import { BasicList } from '../../../../../ui'
import { BULLETIN_LIST_HEADER, UPLOAD_TALLO_PERMITTED_STATES } from './constants'
import { CheckCircle, RemoveCircle } from '@material-ui/icons'
import { green, orange } from '@material-ui/core/colors'
import { Link } from '@material-ui/core'
import BulletinEventsTalloExampleDialogComponent from './bulletin-events-tallo-example-dialog.component'

export const BulletinEventsList = ({ model, certificate_button, tallo_button }) => {
  const { formatMessage } = useIntl()


  let bulletimItems = [
    {
      dg_ts_insert: model?.certificado_IRG3?.fecha_documento_IRG3 ? model?.certificado_IRG3?.fecha_documento_IRG3 :
        model?.presupuesto_exterior?.fecha_pendiente_boletin || model['fecha_solicitud'],
      mensaje: model?.certificado_IRG3?.fecha_documento_IRG3 ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', gap: '5px' }}>
          <CheckCircle style={{ color: green[500] }} />
          <span>{formatMessage({ id: 'pages.gasApplications.wizard.bulletin.event.2' })}</span></div>) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', gap: '5px' }}>
          <RemoveCircle style={{ color: orange[500] }} />
          <span>{formatMessage({ id: 'pages.gasApplications.wizard.bulletin.event.1' })}</span></div>),
      action: certificate_button,
    },

  ]

  const tallo = {
      dg_ts_insert: model?.d_tallo_i?.dg_ts_insert ? model?.d_tallo_i?.dg_ts_insert :
        model?.presupuesto_exterior?.fecha_pendiente_boletin || model['fecha_solicitud'],
      mensaje: model?.d_tallo_i?.dg_ts_insert ? (<div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', gap: '5px' }}>
        <CheckCircle style={{ color: green[500] }} />
        <div>{formatMessage({ id: 'pages.gasApplications.wizard.foto_tallo.event.2' })}<br/><BulletinEventsTalloExampleDialogComponent /></div></div>) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', gap: '5px' }}>
          <RemoveCircle style={{ color: orange[500] }} />
          <div>{formatMessage({ id: 'pages.gasApplications.wizard.foto_tallo.event.1' })}<br/><BulletinEventsTalloExampleDialogComponent />
          </div>
        </div>),
    action: tallo_button,
    }

    if(UPLOAD_TALLO_PERMITTED_STATES.includes(model?.mercado)) {
      bulletimItems.push(tallo)
    }

  return <BasicList config={BULLETIN_LIST_HEADER} data={bulletimItems} />
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import { requests } from '../../../../../core/ajax'
import { feedback } from '../../../../../core/feedback'
import { downloadFile } from '../../../../../core/utils'
import { setApplication } from '../../../slices'

export const usePaymentStep = () => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { getById, put, putById, post } = requests

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const downloadPaymentFile = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/documento', id)
          .then(({ data }) => downloadFile(data['documento'], data['extension'], data['nombre']))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const uploadPaymentFile = useCallback(
    (application, fileData) =>
      new Promise((resolve, reject) => {
        const payload = {
          comprobante_id: fileData.id,
        }
        putById('/presupuesto', application['presupuesto_exterior']['id'], payload)
          .then(({ data }) => dispatch(setApplication(data)))
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const getPaymentInfo = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        post(`/pagos/${id}/realizar_pago`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const validatePayment = useCallback(
    (payload, sociedadId) =>
      new Promise((resolve, reject) => {
        post(`/pagos/validar_pago/${sociedadId}`, payload)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line
  )

  const sendForm = useCallback(
    (payload, id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/${id}`, payload)
          .then((response) => {
            dispatch(setApplication(response.data))
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    []
  )

  return {
    downloadPaymentFile,
    uploadPaymentFile,
    getPaymentInfo,
    validatePayment,
    sendForm,
  }
}

const GRID_ITEM_MID = {
  item: true,
  xs: 12,
  sm: 6,
}

export const DEVOLUCION_EDIT_CONFIG = () => ({
  items: [
    [
      { ref: 'CODIGOIBAN', conf: GRID_ITEM_MID },
      { ref: 'TITULAR', conf: GRID_ITEM_MID },
    ],
  ],
})

export const UPLOAD_BUTTONS_CONFIG = ['CB', 'CCEE']

export const DOCUMENT_ATTACHMENT_CONFIG = {
  CB: {
    id: 'd_CB_id',
    model: 'd_CB',
    type: 'CB',
    field: 'CB',
    label: 'CB.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  CCEE: {
    id: 'd_CCEE_id',
    model: 'd_CCEE',
    type: 'CCEE',
    field: 'CCEE',
    label: 'ccee.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
}

export const I18N_ROOT = 'pages.application.detail.dialogs.editDevolucion.fields'

export const DEVOLUCION_EDIT_FIELDS = ({ intl, combos, onChange, formState, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: I18N_ROOT + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
    onChange,
  }

  return {
    CODIGOIBAN: {
      fromData: (data) => ({
        value: data['iban'],
      }),
      name: 'iban',
      inputProps: {
        pattern:
          '(?:(?:IT|SM)\\d{2}[A-Z]\\d{22}|CY\\d{2}[A-Z]\\d{23}|NL\\d{2}[A-Z]{4}\\d{10}|LV\\d{2}[A-Z]{4}\\d{13}|(?:BG|BH|GB|IE)\\d{2}[A-Z]{4}\\d{14}|GI\\d{2}[A-Z]{4}\\d{15}|RO\\d{2}[A-Z]{4}\\d{16}|KW\\d{2}[A-Z]{4}\\d{22}|MT\\d{2}[A-Z]{4}\\d{23}|NO\\d{13}|(?:DK|FI|GL|FO)\\d{16}|MK\\d{17}|(?:AT|EE|KZ|LU|XK)\\d{18}|(?:BA|HR|LI|CH|CR)\\d{19}|(?:GE|DE|LT|ME|RS)\\d{20}|IL\\d{21}|(?:AD|CZ|ES|MD|SA)\\d{22}|PT\\d{23}|(?:BE|IS)\\d{24}|(?:FR|MR|MC)\\d{25}|(?:AL|DO|LB|PL)\\d{26}|(?:AZ|HU)\\d{27}|(?:GR|MU)\\d{28})$',
      },
      value: formState['iban'],
      placeholder: 'Ej. ES1234567891234567891234',
      fullWidth: true,
      title: txt('codigoIban.label'),
      componentType: 'text',
      ...commonProps,
    },
    TITULAR: {
      fromData: (data) => ({
        value: data['titular_iban'],
      }),
      name: 'titular_iban',
      title: txt('titular.label'),
      ...commonProps,
    },
  }
}

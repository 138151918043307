import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, CircularProgress } from '@material-ui/core'

import { RatingInput } from '../../../../../ui'

const DAYS_MAPPING = {
  1: '1 día',
  2: '2 días',
  '+2': 'Más de 2 días',
}

export const ReviewDialog = ({ reviewId, getReview }) => {
  const { formatMessage: fm } = useIntl()

  const [review, setReview] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    getReview(reviewId)
      .then((data) => {
        setReview(data)
        setLoading(false)
      })
      .catch(() => {
        setError(true)
        setLoading(false)
      })
  }, [getReview])

  if (loading) {
    return (
      <Box display="flex" height={300} justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }
  if (error) {
    return (
      <Box p={4}>
        <Typography variant="h6" color="primary" align="center">
          {fm({ id: 'pages.gasApplications.panel.review.error.label' })}
        </Typography>
      </Box>
    )
  }
  return (
    <Box p={4}>
      <Box mb={3}>
        <Typography variant="h4" color="primary" align="center">
          {fm({ id: 'pages.gasApplications.panel.review.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" gutterBottom>
          {fm({ id: 'pages.gasApplications.panel.review.tiempo_contacto_instalador.label' })}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {DAYS_MAPPING[review.tiempo_contacto_instalador]}
        </Typography>
      </Box>
      <Box mb={2}>
        <RatingInput
          {...{
            name: 'valoracion_tiempo_instalacion',
            value: review.valoracion_tiempo_instalacion,
            readOnly: true,
            title: fm({
              id: 'pages.gasApplications.panel.review.valoracion_tiempo_instalacion.label',
            }),
          }}
        />
      </Box>
      <Box mb={2}>
        <RatingInput
          {...{
            name: 'valoracion',
            value: review.valoracion,
            readOnly: true,
            title: fm({ id: 'pages.gasApplications.panel.review.valoracion.label' }),
          }}
        />
      </Box>
      <Box mb={2}>
        <Typography variant="subtitle1" gutterBottom>
          {fm({ id: 'pages.gasApplications.panel.review.client_comment.label' })}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {review.comentario}
        </Typography>
      </Box>
      {review.respuesta ? (
        <>
          <Typography variant="subtitle1" gutterBottom>
            {fm({ id: 'pages.gasApplications.panel.review.installer_answer.label' })}
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {review.respuesta}
          </Typography>
        </>
      ) : null}
    </Box>
  )
}

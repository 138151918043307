import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../../ui'
import { useGasAppCalls, useGasAppData } from '../../../hooks'
import { useModalContext } from '../../../../../ui/modal'
import { ManageInstallerDialog } from '../../dialogs'

export const SetInstaller = ({ model }) => {
  const { cancelGuild } = useGasAppData()
  const { getInstaller } = useGasAppCalls()
  const {
    actions: { open },
  } = useModalContext()
  const { formatMessage } = useIntl()

  const handleRedirectWP = (observations) => {
    const payload = { solicitud_id: model.id, observaciones_cambio_instalador: observations }
    getInstaller(payload)
      .then((response) => {
        window.location.replace(
          `https://dsc-wordpress.apinedgia-pre.gniinnova.com/configuracion-vivienda/?root=3&id=${response.data.uuid}`
        )
      })
      .catch((response) => console.log(response))
  }

  const handleOpenDialog = () => {
    open({
      Component: ManageInstallerDialog,
      data: { model, handleRedirectWP, cancelGuild },
      type: 'centered',
      closable: true,
    })
  }

  return (
    <ThemeButton labelM="large" color="primary" onClick={handleOpenDialog}>
      {formatMessage({ id: 'global.changeInstaller' })}
    </ThemeButton>
  )
}

import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import { requests } from '../../../../core/ajax'
import { ThemeButton, useModalContext } from '../../../../ui'
import { CommunicationsDialog } from './communications-dialog.component'
import {Badge, Box, IconButton, Tooltip} from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";

export const CommunicationsBtn = ({application,btnType='extended',...props}) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { get } = requests

  const [unreadMssgs, setUnreadMssgs] = useState(0)
  const [loading, setLoading] = useState(true)

  const handleOpenCommsDialog = () => {
    open({
      Component: CommunicationsDialog,
      data: { application,checkUnreadComms },
      type: 'responsive',
      scroll: 'paper',
    })
  }

  const checkUnreadComms = () => {
    setLoading(true)
    get(`/comunicacion_interna/${application.id}/comunicaciones_pendientes`)
      .then(({ data }) => {
        setUnreadMssgs(data.comunicaciones_pendientes)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setUnreadMssgs(application['comunicaciones_pendientes'])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (btnType === 'icon') {

    return (
      <Box display="flex" justifyContent="end" alignItems="center" mb={1}  size="small">
        <Tooltip title="Comunicaciones">
          <span>
        <IconButton aria-label="Abrir comunicaciones" onClick={handleOpenCommsDialog}>
          {(unreadMssgs > 0) && (
          <Badge badgeContent={unreadMssgs} color="secondary" >
            <ChatIcon color="secondary"/>
          </Badge>
          )}
          {(!unreadMssgs || unreadMssgs=== 0) && (
              <ChatIcon color="action"/>
          )}

        </IconButton>
            </span>
        </Tooltip>

      </Box>
    )

  } else {
    return (
      <ThemeButton
        color={unreadMssgs ? 'secondary' : 'primary'}
        size="small"
        onClick={handleOpenCommsDialog}
        startIcon={unreadMssgs ? <NotificationsActiveIcon /> : null}
        disabled={loading}
      >
        {formatMessage({
          id: 'pages.gasApplications.panel.commsBtn.title',
        })}
      </ThemeButton>
    )
  }
  return null;
}

export const budgetRequestPanel2Styles = (theme) => ({
  section_1: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  section_1_1: {
    display: 'flex',
    flexGrow: 2,
  },
  section_1_2: {
    flexGrow: 1,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      marginTop: 10,
    },
  },
  section_1_1_1: {
    minWidth: 48,
    minHeight: 48,
  },
  section_1_1_2: {
    margin: '2px 0 0 4px',
    display: 'column',
  },
  section_2: {
    width: '100%',
  },
  section_2_1: {
    padding: '0 0 12px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  section_2_2: {
    padding: '14px 0 0',
  },
  section_2_2_1: { display: 'flex', flexWrap: 'wrap' },
  section_2_2_1_1: { flexGrow: 1, width: 170 },
  section_2_2_1_2: { display: 'flex', flexGrow: 2 },
  section_2_2_1_2_1: { flex: 1 },
  section_2_2_1_2_2: { alignSelf: 'flex-end', textAlign: 'right', width: 100 },
  section_2_3: {
    padding: '14px 0',
  },
  section_2_3_1: { display: 'flex', flexWrap: 'wrap', marginBottom: 10 },
  section_2_3_1_1: { flexGrow: 1, width: 170 },
  section_2_3_1_2: { display: 'flex', flexGrow: 2 },
  section_2_3_1_2_1: { flex: 1 },
  section_2_3_1_2_2: { alignSelf: 'flex-end', textAlign: 'right', width: 100 },
  section_2_3_2: { display: 'flex', justifyContent: 'flex-end' },
  section_2_3_2_1: {
    flex: 1,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  section_2_3_2_2: {
    textAlign: 'right',
    width: 100,
  },
  section_2_3_3: { display: 'flex', justifyContent: 'flex-end' },
  section_2_3_3_1: {
    flex: 1,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  section_2_3_3_2: { textAlign: 'right', width: 100 },
  section_2_4: {
    padding: '14px 0 0',
  },
  section_2_4_1: { display: 'flex', flexWrap: 'wrap' },
  section_2_4_1_1: { flexGrow: 1, width: 170 },
  section_2_4_1_2: { flexGrow: 2, paddingRight: 100 },
  section_2_4_2: { display: 'flex', justifyContent: 'flex-end' },
  section_2_4_2_1: {
    flex: 1,
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  section_2_4_2_2: { textAlign: 'right', width: 100 },
})

import './core/polyfill/index.js'

import React from 'react'
import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'
import { Bootstrap } from './bootstrap'

import './index.scss'

ReactDOM.render(
  // <React.StrictMode>
  <Bootstrap />,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

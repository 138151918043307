export const CONTRACT_LIST_HEADER = [
  {
    key: 'event_date',
    label: 'Fecha',
  },
  {
    key: 'observations',
    label: 'Observaciones',
  },
]

export const CONTRACT__COMPANIES_URL =
  'https://www.nedgia.es/quierogasnatural/wp-content/uploads/sites/6/2019/11/2019-11-listado-de-comercializadores-de-gas-natural-12-11-2019-.pdf'

export let MENU_ITEMS = [
  {
    label: 'layout.private.menu.home',
    path: '/home',
  },
  {
    label: 'layout.private.menu.applications',
    path: '/gas-applications',
  },
  // {
  //   label: 'layout.private.menu.manage',
  //   children: [
  //     {
  //       label: 'layout.private.menu.readings',
  //       path: '/readings',
  //     },
  //     {
  //       label: 'layout.private.menu.inspections',
  //       path: '/inspections',
  //     },
  //   ],
  // },
  {
    label: 'layout.private.menu.contact',
    path: '/contact',
  },
]

export let DEFAULT_ROUTE = '/home'

export let PROFILE_ROUTE = '/profile'

export let SETTINGS_ROUTE = '/settings'

import React, { useState /*,useEffect*/ } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Typography, Divider, Hidden, Button } from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'
// import { useDispatch } from 'react-redux'

import { feedback } from '../../../../../core/feedback'
import { FileUploader } from '../../../../../ui'
import { ReactComponent as PaymentIcon } from '../../../../../resources/images/icon_payment.svg'
import { APPLICATION_STATES } from '../../../constants'
import { PAYMENT_NEEDED_STATES, CAN_SELECT_INSTALLER } from './constants'
import { usePaymentStep } from './payment-step.hook'
// import { PaymentProcessor } from './payment-processor.component'
import { CancelBtn, GetInstallerTxt } from '../../buttons'
import TransferIcon from '../../../../../resources/icons/TransferIcon'
import CreditCardIcon from '../../../../../resources/icons/CreditCardIcon'
import { PAYMENT_STEP_COMPLETED_STATES, PAYMENT_FILE_SIZE } from './constants'
import { paymentStepStyles, optionButtonStyles } from './payment-step.styles'
import { useModalContext } from '../../../../../ui'
import { ThemeButton } from '../../../../../ui'
import { BillingDataDialog } from '../../dialogs'
// import { useGasAppCalls } from '../../../hooks'
//import { setApplication } from '../../../slices'

const OptionButton = withStyles(optionButtonStyles)(
  ({ classes, label, icon, active = false, ...props }) => {
    return (
      <Button classes={{ root: classes.root }} variant="contained" {...props}>
        {icon}
        <Typography variant="subtitle2">{label}</Typography>
      </Button>
    )
  }
)

export const PaymentStepComponent = withStyles(paymentStepStyles)(({ classes, model }) => {
  const { formatMessage, formatNumber, formatDate } = useIntl()
  const { downloadPaymentFile, uploadPaymentFile } = usePaymentStep()
  // const { getGasApp } = useGasAppCalls()
  const {
    actions: { open },
  } = useModalContext()
  // const dispatch = useDispatch()

  const paymentPending = model['estado'] === APPLICATION_STATES.BUDGET_ACCEPTED.KEY

  // const [option, setOption] = useState(model['presupuesto_exterior']['d_comprobante'] ? 2 : 1)
  const [option, setOption] = useState(1) // eslint-disable-line

  const canUploadFile = model['estado'] === APPLICATION_STATES.BUDGET_ACCEPTED.KEY
  const freeL = model['presupuesto_exterior']['distancia_asumida_nedgia'] || '-'
  const priceL = model['presupuesto_exterior']['precio_metro_tuberia']
    ? formatNumber(model['presupuesto_exterior']['precio_metro_tuberia'], {
        style: 'currency',
        currency: 'EUR',
      })
    : '-'
  const paymentNeeded =
    model['presupuesto_exterior']['distancia_real'] >
    model['presupuesto_exterior']['distancia_asumida_nedgia']

  const getPaymentState = () => {
    if (!paymentNeeded) return 'free'
    else if (APPLICATION_STATES.BUDGET_ACCEPTED.KEY === model.estado) return 'missing'
    else if (
      APPLICATION_STATES.PAYMENT_VALIDATION.KEY === model.estado ||
      APPLICATION_STATES.ORDER_PENDING.KEY === model.estado
    )
      return 'pending'
    else return 'accepted'
  }
  const payment_ok = null
  const payment_date = formatDate(Date.now())

  const canChangeInstaller =
    model['camino_solicitud'] === '01' &&
    (CAN_SELECT_INSTALLER[model.estado] ||
      (model?.estado === '37' && model.certificado_IRG3?.id === null)) &&
    (model['d_IRG3'] === null || model['certificado_IRG3_id'] === null) &&
    !model['gran_consumo_id']

  const handleUploadFile = (data) => {
    handleOpenModal(true, data)
  }

  const handleDownloadFile = (fileId) => downloadPaymentFile(fileId)

  const handleUploadError = (data) => feedback('error', data.mssg)

  const handleOpenModal = (isTransferencia, data) => {
    open({
      Component: BillingDataDialog,
      data: { model, isTransferencia, uploadPaymentFile, data },
      maxWidth: 'md',
      type: 'responsive',
      closable: true,
    })
  }

  return (
    <Box p={2} pt={1}>
      <Box display="flex" mb={1} flexWrap="wrap">
        <Box display="flex" alignItems="center" flexGrow={2}>
          <Hidden xsDown>
            <PaymentIcon />
          </Hidden>
          <Typography variant="h5">
            {formatMessage({
              id: `pages.gasApplications.wizard.payment.title${paymentNeeded ? 1 : 2}`,
            })}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" flexGrow={1} alignItems="flex-end">
          <Box display="flex" justifyContent="flex-start">
            <Typography variant="body2" className={classes.marginRight}>
              {formatMessage({ id: 'pages.gasApplications.wizard.payment.extra.label' })}:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {formatMessage({
                id: `pages.gasApplications.wizard.payment.extra.state.${getPaymentState()}`,
              })}
            </Typography>
          </Box>
          {payment_ok && model['presupuesto_exterior']['d_comprobante'] ? (
            <Box>
              <Typography variant="body2" color="textSecondary">
                {formatMessage({ id: 'pages.gasApplication.wizard.card.payment.succes' })}-
                {payment_date}
              </Typography>
            </Box>
          ) : (
            payment_ok && (
              <Box>
                <Typography variant="body2" color="textSecondary">
                  {formatMessage({ id: 'pages.gasApplication.wizard.card.payment.noSucces' })}-
                  {payment_date}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>
      <Typography variant="body2" paragraph>
        {formatMessage({ id: 'pages.gasApplications.wizard.steps.1.tooltip' })}
      </Typography>
      <Divider light />
      <Box mt={2}>
        {paymentNeeded ? (
          model['presupuesto_exterior']['d_comprobante'] ? (
            <>
              <Typography variant="body1" gutterBottom>
                {formatMessage({ id: 'pages.gasApplications.wizard.payment.message5' })}
              </Typography>
              <Box flexGrow={1} width="50%">
                <Typography variant="subtitle2">
                  {model['presupuesto_exterior']['d_comprobante']['nombre']}
                </Typography>

                <span
                  onClick={() =>
                    handleDownloadFile(model['presupuesto_exterior']['d_comprobante']['id'])
                  }
                >
                  <Typography variant="body2" className={classes.downloadLink}>
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.payment.button3.label',
                    })}
                  </Typography>
                </span>
              </Box>
            </>
          ) : (
            <>
              <Box display="flex" flexWrap="wrap" mt={2} mb={2}>
                <Box mr={2}>
                  <OptionButton
                    icon={<TransferIcon className={classes.optionIcon} />}
                    label={formatMessage({
                      id: 'pages.gasApplications.wizard.payment.option2.label',
                    })}
                    active={option === 2}
                    disabled={PAYMENT_STEP_COMPLETED_STATES[model.estado]}
                    onClick={() => setOption(2)}
                  />
                </Box>

                <OptionButton
                  icon={<CreditCardIcon className={classes.optionIcon} />}
                  label={formatMessage({
                    id: 'pages.gasApplications.wizard.payment.option1.label',
                  })}
                  active={option === 1}
                  disabled={PAYMENT_STEP_COMPLETED_STATES[model.estado]}
                  onClick={() => setOption(1)}
                />
              </Box>
              <Divider light />
              <Box mt={2}>
                <Typography variant="body1" gutterBottom>
                  {option === 1
                    ? !PAYMENT_NEEDED_STATES[model.estado]
                      ? formatMessage({ id: 'pages.gasApplications.wizard.payment.message5' })
                      : formatMessage({ id: 'pages.gasApplications.wizard.payment.message4' })
                    : payment_ok === null
                    ? formatMessage(
                        { id: 'pages.gasApplications.wizard.payment.message1' },
                        { iban: model['presupuesto_exterior']['iban'] }
                      )
                    : null}
                </Typography>

                {option !== 1 ? (
                  <Typography variant="body1">
                    {payment_ok === null
                      ? formatMessage({ id: 'pages.gasApplications.wizard.payment.message2' })
                      : null}
                  </Typography>
                ) : null}
              </Box>
            </>
          )
        ) : (
          <Typography variant="body1">
            {formatMessage(
              { id: 'pages.gasApplications.wizard.payment.message3' },
              { freeL, priceL }
            )}
          </Typography>
        )}
        <Divider light style={{ marginTop: '0.5em', marginBottom: '0.5em' }} />
        <Box display="flex" justifyContent={canChangeInstaller ? 'space-between' : 'flex-end'}>
          {canChangeInstaller ? (
            <Box display="flex" height="100%" alignItems="center" mt={4}>
              <GetInstallerTxt {...{ model }} />
            </Box>
          ) : null}
          <div style={{ display: 'flex' }}>
            {option === 1 &&
              PAYMENT_NEEDED_STATES[model.estado] &&
              !model['presupuesto_exterior']['d_comprobante'] && (
                <Box textAlign="right" mr={2}>
                  <ThemeButton
                    color="primary"
                    labelM="large"
                    disabled={!paymentPending}
                    onClick={() => handleOpenModal(false)}
                  >
                    {formatMessage({
                      id: 'pages.gasApplications.wizard.payment.form.pagar.title',
                    })}
                  </ThemeButton>
                </Box>
              )}
            {option !== 1 &&
            PAYMENT_NEEDED_STATES[model.estado] &&
            !model['presupuesto_exterior']['d_comprobante'] &&
            canUploadFile ? (
              <Box textAlign="right" flexGrow={1} display="flex" justifyContent="flex-end">
                <FileUploader
                  maxSize={PAYMENT_FILE_SIZE}
                  applicationId={model['id']}
                  budgetId={model['presupuesto_exterior']['id']}
                  label={formatMessage({
                    id: 'pages.gasApplications.wizard.payment.button2.label',
                  })}
                  icon={<PublishOutlinedIcon />}
                  extra={formatMessage(
                    {
                      id: 'pages.gasApplications.wizard.payment.uploadMessage',
                    },
                    { size: PAYMENT_FILE_SIZE / 1000000 }
                  )}
                  color="primary"
                  type="comprobante_pago"
                  accept={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
                  onSuccess={handleUploadFile}
                  onError={(data) => handleUploadError(data)}
                  style={{ marginRight: '1.5em' }}
                />
              </Box>
            ) : null}
            <Box display="flex" alignItems="center">
              <CancelBtn {...{ model }} />
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  )
})

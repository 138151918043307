import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import {Box, Typography, Grid, CircularProgress, Chip} from '@material-ui/core'

import { ThemeButton, FormPanel, FileUploader, UploadTooltip } from '../../../../../ui'
import { requests } from '../../../../../core/ajax'
import {
  DEVOLUCION_EDIT_CONFIG,
  DEVOLUCION_EDIT_FIELDS,
  DOCUMENT_ATTACHMENT_CONFIG,
  UPLOAD_BUTTONS_CONFIG,
} from './add-certificateData-form.constants'
import { feedback } from '../../../../../core/feedback'
import {AttachFile} from "@material-ui/icons";

export const EditCertificateDataForm = ({
  close,
  model,
  documentIdentification,
  uploadBulletinFile,
  data,
  handleSuccessfulOperation,
  handleErrors,
}) => {
  const intl = useIntl()
  const { formatMessage: fm } = intl
  const combos = useSelector((state) => state.combos)
  const { put } = requests

  const [formState, setFormState] = useState(model)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [uploadButtonArray, setUploadButtonArray] = useState([])
  const [documentData, setDocumentData] = useState({
    d_CB: model.d_CB ? model.d_CB : null,
    d_CCEE: model.d_CCEE ? model.d_CCEE : null,
  })

  const handleUploadFile = (data, buttonData) => {
    setDocumentData({ ...documentData, [buttonData]: data })
    setFormState({ ...formState, [`${buttonData}_id`]: data.id })
  }

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (model) {
      setUploadButtonArray(
        UPLOAD_BUTTONS_CONFIG.map((doc) => DOCUMENT_ATTACHMENT_CONFIG[doc]).map((doc) => {
          return {
            ...doc,
            title: intl.formatMessage({
              id: `pages.model.view.documentViewer.documents.${doc.field}`,
            }),
            label: intl.formatMessage({
              id: 'global.documents.button.add',
            }),
            name: documentData[doc.model]?.nombre || '',
          }
        })
      )
    }
  }, [model, documentData, intl])

  const handleSubmit = (e) => {
    const payload = {
      iban: formState.iban,
      titular_iban: formState.titular_iban,
      d_CB_id: formState.d_CB_id,
      d_CCEE_id: formState.d_CCEE_id,
    }
    e.preventDefault()
    setLoading(true)

    put(`/solicitudes/${model.id}`, payload)
      .then(() => {
        setLoading(false)
        uploadBulletinFile(model, data, documentIdentification)
          .then(() => {
            handleSuccessfulOperation('Documento subido')
          })
          .catch(() => {
            handleErrors('Error al subir el documento')
          })
        close()
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fieldList = useMemo(
    () => DEVOLUCION_EDIT_FIELDS({ combos, intl, formState, onChange: handleChange }),
    [combos, formState] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const block = useMemo(
    () => DEVOLUCION_EDIT_CONFIG({ intl, formState }),
    [formState] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleUploadError = (data) => {
    feedback('error', data?.mssg || 'La subida del documento no se pudo completar.')
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box m={6}>
        <Box mb={3}>
          <Typography variant="h5" color="primary" style={{textAlign:'center'}}>
            {intl.formatMessage({ id: 'pages.addCertificate.dialogs.new.title' })}
          </Typography>
        </Box>
        <FormPanel {...{ data: formState, combos, intl, block, fieldList }} />
        <Grid container spacing={1}>
          <Box mt={2}>
            <Typography variant="h6" color="primary">
              {'Documentos'}
            </Typography>
          </Box>
          {uploadButtonArray.map((uploadButton) => {
            return (
              <Grid key={uploadButton.model} item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Typography display="inline" variant="body2">
                        {uploadButton.title}
                      </Typography>
                      <UploadTooltip file={uploadButton} />
                    </Box>
                    {
                      uploadButton.name && (
                        <Chip
                          icon={<AttachFile />}
                          label={uploadButton.name}
                          variant="outlined"
                          color={uploadButton.estado === 'Rechazado' ? 'secondary' : 'initial'}
                        />
                      )
                    }
                  </Box>
                  <FileUploader
                    applicationId={model['id']}
                    budgetId={model['presupuesto']?.id}
                    clientId={model['usuario']['id']}
                    onSuccess={(data) => handleUploadFile(data, uploadButton.model)}
                    onError={handleUploadError}
                    {...uploadButton}
                  >
                    {(props) => (
                      <span
                        className="MuibuttonBase-root MuiButton-root MuiButton-outlined"
                        style={{ marginLeft: 10 }}
                        disabled={loading}
                      >
                        {props.loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          intl.formatMessage({ id: 'global.documents.button.add' })
                        )}
                      </span>
                    )}
                  </FileUploader>
                </Box>
              </Grid>
            )
          })}
        </Grid>
        {errorMssg && (
          <Box mt={1} bgcolor="grey">
            <Typography variant="body2" color="error">
              {errorMssg}
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <Box width={190} p="0 5px">
            <ThemeButton
              type="submit"
              color="primary"
              fullWidth
              loading={loading}
              disabled={loading}
            >
              {fm({ id: 'global.save' })}
            </ThemeButton>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

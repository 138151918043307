export const encodeToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let result = ''
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = (e) => {
      result = e.target.result.split(',')[1]
      resolve(result)
    }
    reader.onerror = (e) => {
      reject(e)
    }
  })
}

export const isACorrectFormat = (filename, acceptedFormats) => {
  let acceptedExtensions = acceptedFormats.map((item) => item.split('/')[1])
  let filenameAndExtension = filename.toLowerCase().split('.')
  // without filename or extension
  if (filenameAndExtension.length === 1) {
    return false
  }
  let extension = filenameAndExtension[filenameAndExtension.length - 1]
  return acceptedExtensions.indexOf(extension) > -1
}

const getCompleteFileType = (type) => {
  switch (type) {
    case 'pdf':
      return `application/${type}`
    case 'jpg':
    case 'jpeg':
    case 'png':
      return `image/${type}`
    case 'xls':
      return type
    default:
      return `application/${type}`
  }
}

export const covertStrToBlob = (str, fileExtension) => {
  const hexStr = str.slice(2)
  const buf = new ArrayBuffer(hexStr.length / 2)
  const byteBuf = new Uint8Array(buf)
  for (let i = 0; i < hexStr.length; i += 2) {
    byteBuf[i / 2] = parseInt(hexStr.slice(i, i + 2), 16)
  }
  const blob = new Blob([byteBuf], { type: getCompleteFileType(fileExtension) })
  return blob
}

export const downloadFile = (data, fileExtension, fileName) => {
  const base64Data = data.split("'")[1]
  const link = document.createElement('a')
  link.target = '_blank'
  link.href = `data:${getCompleteFileType(fileExtension)};base64,${base64Data}`
  link.download = `${fileName}.${fileExtension}`
  // link.rel = 'noopener noreferrer'
  // document.body.appendChild(link)
  link.click()
}

import React, { useMemo } from 'react'
import { Box } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { Wizard } from '../wizard'
import { useAppWizardSteps } from './gas-app-wizard.hook'
import { APPLICATION_FULL_PATHS } from '../../constants'
import { ThemeButton } from '../../../../ui'
import { useModalContext } from '../../../../ui'
import { CancelRequestDialog } from '../dialogs/cancel-app-dialog'
import { APPLICATION_STATES } from '../../constants/application-states.constants'
import { useGasAppData } from '../../hooks'

export const GasAppWizardComponent = ({ model }) => {
  const {
    actions: { open },
  } = useModalContext()
  const { updateGasApps, cancelApplication, rollbackApp } = useGasAppData()
  const {
    budgetWizardStep,
    paymentWizardStep,
    bulletinWizardStep,
    bulletinWizardExteriorStep,
    networkWizardStep,
    contractWizardStep,
    putgaWizardStep,
  } = useAppWizardSteps()
  const { formatMessage } = useIntl()

  const handleAnswerRequest = () => {
    open({
      Component: CancelRequestDialog,
      data: { application: model, cancelApplication, updateGasApps, rollbackApp },
      type: 'centered',
    })
  }

  const steps = useMemo(
    () =>
      APPLICATION_FULL_PATHS[model['camino_solicitud']]
        ? [
            budgetWizardStep,
            paymentWizardStep,
            bulletinWizardExteriorStep,
            networkWizardStep,
            contractWizardStep,
            putgaWizardStep,
          ]
        : [bulletinWizardStep, contractWizardStep, putgaWizardStep],
    [model] // eslint-disable-line
  )

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end" minWidth="100%">
      <Wizard {...{ steps, model }} />
      {APPLICATION_STATES.CLIENT_CANCEL_PENDING.KEY === model.estado ? (
        <Box display="flex" justifyContent="flex-end">
          <ThemeButton onClick={handleAnswerRequest}>
            {formatMessage({ id: 'pages.gasApplications.dialogs.pendingApps.Request' })}
          </ThemeButton>
        </Box>
      ) : null}
    </Box>
  )
}
